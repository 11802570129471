import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Header, Footer } from '../../Components/AdminComponents';
import { Body, Container, TitleContainer } from '../../Components/StyledComponents';
import Button from '../../Components/Button';
import Modal from 'react-modal';
import axios from 'axios';
import Ajv from 'ajv';
import styled from 'styled-components';
import { EditCarInformation } from './EditCarInformation';
//api
import { detailDataLabel, registerForm, statuses, INSERT_URL, Colors } from '../../config';
//functions
import { Now } from '../../Functions';


const CarRegistration = () => {
  const history = useHistory();
  const account = useParams();
  const account_name = account.account_name;

  const [data, setData] = useState({ ...registerForm });
  const [error, setError] = useState('');
  const [isRegistering, setIsregistering] = useState(true);


  const finishRegistering = useCallback(() => {
    history.push({ pathname: `/admin/${account_name}` })
  }, []);

  const handleRegistering = useCallback((enteredData) => {
    const registerCarInfo = async () => {
      try {
        const resultUserInfo = await axios.post(INSERT_URL + "/user_info",
          {
            name: enteredData.name,
            phone: enteredData.phone,
            address: enteredData.address,
            mail: enteredData.mail
          });

        const date = Now();
        const resultCarInfo = await axios.post(INSERT_URL + "/car_info",
          {
            maker: enteredData.maker,
            car_type: enteredData.carType,
            age: enteredData.age,
            mileage: enteredData.mileage,
            user_id: resultUserInfo.data.insertId,
            status: 0,
            account_name: account_name,
            create_date: date,
            update_date: date,
            register_date: null,
          });
        setData({ ...enteredData })
        setIsregistering(isRegistering => !isRegistering)
      } catch (error) {
        setError(error)
      }
    }

    registerCarInfo();
  }, []);


  return (
    <Container >
      <Header />
      <Body>
        <TitleContainer>
          <div>
            査定依頼車追加フォーム
          </div>
        </TitleContainer>
        {
          isRegistering ?
            <CarInfoContainer>
              <EditCarInformation
                data={data}
                setShowingData={setData}
                changeEditState={finishRegistering}
                onChange={(enteredData) => handleRegistering(enteredData)}
                setError={setError}
                error={error}
                isEditing={false}
              />
            </CarInfoContainer>
            :
            <CarInfoContainer>
              <CarInformation data={data} detailDataLabel={detailDataLabel} />
            </CarInfoContainer>
        }
      </Body>
      <Footer />
    </Container >
  )
}

const CarInformation = props => {
  const { data, detailDataLabel, } = props;
  const { account_name } = useParams();
  const statusmap = new Map(statuses);

  return (
    <>
      <div style={{ width: '80%', height: '90%', overflowY: 'auto', justifySelf: 'center', paddingTop: 40 }}>
        {
          Object.keys(data).map((item, index) => {
            if (detailDataLabel[item]) {
              let label = data[item];
              if (item === 'status') {
                label = statusmap.get(data[item]);
              }
              return (
                <div style={{ height: 'auto', width: '100%', marginBottom: 3, padding: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                  <div style={{ fontSize: 14, width: '30%', color: Colors.labelColor }}>
                    {detailDataLabel[item]}
                  </div>
                  <div style={{ fontSize: 16, overflowX: 'auto', width: '50%' }}>
                    {label}
                  </div>
                </div>
              )
            }
          })
        }
      </div>
      <div style={{ alignSelf: 'center', marginTop: 30 }}>
        <Link
          to={{
            pathname: `/admin/${account_name}`,
          }}
          style={{ color: 'gray', width: 'auto', height: '100%', marginTop: 20 }}
        >
          <Button>
            戻る
          </Button>
        </Link>
      </div>

    </>
  )
}

const CarInfoContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 50px 50px 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background-color: ${Colors.bodyBackgroundColor};
  @media screen and (max-width: 620px) {
    width: 90%;
    padding: 0px 10px 50px 10px;
  }
`

export default CarRegistration;