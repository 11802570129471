import React from 'react';
import { Link } from 'react-router-dom';
//components
import { Header } from '../../Components/AdminComponents';
import { LoginStyle } from '../../Components/StyledComponents';
//services
import AuthService from '../../Services/AuthService';
//config & styles
import { container } from './LogIn';
import { TextSize } from '../../config';

const LogOut = () => {
  AuthService.logout();

  return (
    <div style={container}>
      <Header />
      <LoginStyle>
        <div style={{ fontSize: TextSize.large, fontWeight: 'bolder', padding: '15%' }}>
          Admin Console
        </div>
        <div>
          <h3>ログアウトしました</h3>
          <div style={{ height: 20 }} />
          <div className="text-center">
            <Link to="/admin/login">ログイン画面へ</Link>
          </div>
        </div>
      </LoginStyle>
    </div>
  )
};

const carListContainer = {
  width: '35vw',
  height: '60vh',
  backgroundColor: 'white',
  margin: 'auto',
  marginTop: '10vh',
};

export default LogOut;