import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { STATUS_UPDATE_URL, statuses, TextSize, StatusColor } from "../config";
import { CardListStyle, ListItemInfoStyle, ListItemButtonStyle, ListImageStyle } from './StyledComponents';
import Button from './Button';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import '../index.css';

const CarList = (props) => {

  const { listData, dataLabel, updateCarData } = props;
  const { account_name } = useParams();
  const statusAndUserInfoLabel = { create_date: '依頼日時', name: '依頼者名', status: 'ステータス' }
  // const handleChange = async (status, id) => {
  //   await axios.post(STATUS_UPDATE_URL, { id: id, status: status })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         updateCarData(id, status);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  // }

  return (
    <div style={{ overflowY: 'auto', zIndex: 15 }}>
      <TableColumn />
      {
        listData.map((data, index) => {
          return (
            <CardListStyle>
              <ListItemInfoStyle>
                <div style={{ height: '100%', width: 130, alignSelf: 'center', padding: 10 }}>
                  {
                    data.img_name ?
                      <a href={data.img_name} target='_blank' rel='noopener'>
                        <ListImageStyle src={data.img_name} alt={'default image'} />
                      </a>
                      :
                      <img src={require('../assets/1.png')} alt={'default image'} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                  }
                </div>
                <div style={{ width: '30%', marginLeft: 15, alignSelf: 'center' }}>
                  <div style={{ marginBottom: 15 }}>
                    <StatusInfo item={'create_date'} data={data.create_date} label={statusAndUserInfoLabel['create_date']} />
                    <StatusInfo item={'name'} data={data.name} label={statusAndUserInfoLabel['name']} />
                  </div>
                  <StatusInfo item={'status'} data={data.status} label={statusAndUserInfoLabel['status']} />
                </div>
                <div style={{ width: '30%', marginLeft: 20, marginRight: 'auto', alignSelf: 'center' }}>
                  {
                    Object.keys(data).map((item, ind) =>
                      dataLabel[item] && <Row label={dataLabel[item]} data={data} item={item} index={ind} />
                    )
                  }
                </div>
              </ListItemInfoStyle>
              <ListItemButtonStyle >
                <Link
                  to={{
                    pathname: `/admin/${account_name}/${data.id}`,
                    state: { data: data },
                  }}
                  style={{ textDecoration: 'none', color: '#ffffff' }}
                >
                  <Button>
                    詳細へ
                  </Button>
                </Link>
              </ListItemButtonStyle>
            </CardListStyle>
          )
        })
      }
    </div >
  );
};

const StatusInfo = props => {
  const statusmap = new Map(statuses);
  const statusColor = new Map(StatusColor);


  const { data, label, item } = props;
  let value = data;
  if (item === 'create_date') {
    const time = value.split('T');
    const date = time[0];
    const hour = time[1].split(':', 2).join(':');
    value = date + ' ' + hour;
  }

  return (
    <div style={{ display: 'flex', marginBottom: 5, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
      <div style={{ width: '40%', fontSize: TextSize.small, color: 'gray' }}>{label}</div>
      {
        item === 'status' ?
          <div style={{ width: '50%', fontSize: TextSize.small, color: 'white', padding: 3, backgroundColor: `${statusColor.get(value)}`, textAlign: 'center', borderRadius: 5 }}>
            {statusmap.get(value)}
          </div>
          :
          <div style={{ width: '60%', fontSize: TextSize.small }}>{value}</div>
      }
    </div>
  )
}

const Row = props => {
  const { label, data, item, index } = props;
  return (
    <div key={index.toString()} style={{ display: 'flex', marginBottom: 5, flexDirection: 'row', justifyContent: 'flex-start' }}>
      <div style={{ width: '40%', fontSize: TextSize.small, color: 'gray' }}>{label}</div>
      <div style={{ width: '60%', fontSize: TextSize.small }}>{item === 'mileage' ? data[item] + ' km' : data[item]}</div>
    </div>
  )
};

const TableColumn = () => {
  return (
    <div style={{ height: 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', fontSize: TextSize.small, fontWeight: '600' }}>
      <ColumnInfoStyle>
        <div style={{ width: 130, backgroundColor: '#B9B9B9', marginRight: 1, paddingLeft: 10, display: 'flex', alignItems: 'center' }}>写真</div>
        <div style={{ width: 'calc(100% - 120px)', backgroundColor: '#B9B9B9', marginRight: 1, paddingLeft: 10, display: 'flex', alignItems: 'center' }}>依頼情報</div>
      </ColumnInfoStyle>
      <ColumnDetailStyle>査定情報の詳細</ColumnDetailStyle>
    </div >
  )
}
const ColumnInfoStyle = styled.div`
  width: calc(100% - 120px);
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 620px) {
    width: 100%
  }
`
const ColumnDetailStyle = styled.div`
  width: 120px;
  display: flex;
  flex-direction: row;
  background-color: #B9B9B9;
  margin-right: 1px;
  padding-left: 10px;
  align-items: center;
  @media screen and (max-width: 620px) {
    display: none;
  }
`
export default CarList;
