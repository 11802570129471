import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Colors } from '../config';


const Button = (props) => {

  const { isCancel, onClick, buttonStyle } = props;

  const btnClicked = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const getButtonStyle = () => {
    switch (buttonStyle) {
      case 'cancel':
        return {
          backgroundColor: Colors.cancelBtnColor,
          color: 'black',
          border: '0px',
          margin: '0 8px 0 0',
          fontSize: 10,
        }
      case 'delete':
        return {
          backgroundColor: 'white',
          color: Colors.delete
        }
      default:
        return {};
    }
  }

  return (
    <ButtonStyle style={getButtonStyle()} onClick={() => btnClicked()}>
      {
        props?.children
      }
    </ButtonStyle >
  );
};

export const ButtonStyle = styled.button`
  min-width: 75px;
  height: 32px;
  padding: 0.3em;
  font-size: 11px;
  background-color: ${Colors.enable};
  border: 1px solid;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 100ms;
  @media screen and (max-width: 620px) {
    width: 60px;
    margin-bottom: 10px;
  }
  &:hover {
    background-color: ${Colors.btnHoverColor};
  } 
`


export default Button