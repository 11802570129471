import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import NotFound from './Screens/NotFound';
import AuthService from './Services/AuthService';

class PrivateRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isLoggedIn: false,
      isAuthenticated: false
    };
  };

  async componentDidMount() {
    const { computedMatch } = this.props;
    const isLoggedIn = AuthService.isLoggedIn();
    const store_info = AuthService.getCurrentUser();
    let isAuthenticated = false;

    if (isLoggedIn && computedMatch) {
      if (store_info.account_name === computedMatch.params.account_name) {
        isAuthenticated = true;
      }
    }
  
    this.setState({
      loading: false,
      isLoggedIn: isLoggedIn,
      isAuthenticated: isAuthenticated
    });
  };

  render() {
    const { component: Component, ...rest } = this.props;
    const { loading, isLoggedIn, isAuthenticated } = this.state;

    if (loading) {
      return <div className="loading">Loading...</div>
    }

    return (
      <Route {...rest} render={() => {
        if (!isLoggedIn) {
          return <Redirect to={{ pathname: '/admin/login', state: { from: this.props.location } }} />
        } else if (!isAuthenticated) {
          return <NotFound></NotFound>
        }
        return <Component {...this.props} />
      }}
      />
    )
  }
}

export default PrivateRoute