import React, { useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import axios from 'axios';
import { FETCH_URL, TextSize } from '../../config';
//components
import Title from '../../Components/Title';
import SubmitButton from '../../Components/SubmitButton';
import { CustomerContainer } from '../../Components/StyledComponents';
//functions
import { phoneNumberNoneProblem, formatTel } from '../../Functions';

const Phone = () => {
    const history = useHistory();
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const { account_name } = useParams();

    const checkForm = useCallback(() => {
        if (!phoneNumberNoneProblem(phone)) {
            setError('電話番号の形式が正しくありません');
            return true;
        }

        const formatedPhoneNumber = formatTel(phone);
        const isHaihun = RegExp('-');

        if (!isHaihun.test(formatedPhoneNumber)) {
            setError('電話番号の形式が正しくありません');
            return true;
        }

        const getGeneralInfo = async (phoneNumber) => {
            return await axios.get(FETCH_URL + `/generalinfo?phone=${phoneNumber}&account_name=${account_name}`)
                .then((response) => {
                    if (response.status === 200) {
                        return response.data[0];
                    }
                })
                .then((result) => {
                    if (result) {
                        const generalInfo = {
                            maker: result.maker,
                            carType: result.car_type,
                            age: result.age,
                            mileage: result.mileage,
                            name: result.name,
                            phone: result.phone,
                            address: result.address,
                            mail: result.mail,
                            isUpdate: true,
                            carId: result.id
                        };

                        history.push({
                            pathname: `/user/${account_name}/input`,
                            state: { generalInfo: generalInfo }
                        });
                    } else {
                        history.push({
                            pathname: `/user/${account_name}/pincode`,
                            state: { phone: formatedPhoneNumber }
                        });
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                });
        }

        getGeneralInfo(formatedPhoneNumber);
    }, [history, phone, account_name]);

    return (
        <CustomerContainer>
            <div style={{ marginTop: '20%' }} />
            {error && (
                <div style={{ whiteSpace: 'pre-line' }}>
                    <Alert variant="danger">{error}</Alert>
                </div>
            )}
            <Title titleText='電話番号を入力してください。' />
            <div style={{ textAlign: 'center', padding: '8% 0px 20% 0' }}>
                <input type="tel" name="phone" onBlur={(e) => setPhone(e.target.value)} style={inputContainer} placeholder={'03-1234-5678'} />
            </div >
            <div style={{ fontSize: TextSize.small, fontWeight: '600', textAlign: 'center', marginBottom: '20%' }}>
                <div>
                    送信後、確認コードを含む
                </div>
                <div>
                    テキストメッセージが届きます。
                </div>
            </div>
            <SubmitButton buttonText='続ける' onClick={checkForm} isEnabled={true} />
        </CustomerContainer >
    );
};


const inputContainer = { fontSize: 14, maxWidth: 300, width: '80%', textAlign: 'center' };

export default Phone;