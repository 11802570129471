import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Header, Footer } from '../../Components/AdminComponents';
import { Body, TitleContainer, Container, ItemDisplayStyle, ImageDisplayStyle, InfoDisplayStyle, InfoButtonDisplayStyle } from '../../Components/StyledComponents';
import { EditCarInformation } from './EditCarInformation';
import { EmailModal } from './Modal/EmailModal';
import { DeleteModal } from './Modal/DeleteModal';
import axios from 'axios';
import styled from 'styled-components';
import Button from '../../Components/Button';
//api
import { IMG_DESC_URL, USER_INFO_INSERT_URL, CAR_INFO_UPDATE_URL, CAR_INFO_DELTE_URL, statuses, StatusColor, detailDataLabel, Colors, TextSize } from '../../config';
//services
import ImageService from '../../Services/ImageService';
import EmailService from '../../Services/EmailService';
//functions
import { Now } from '../../Functions';

const CarDetail = () => {
  const [showingData, setShowingData] = useState({});
  const [imageDesc, setImageDesc] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataExists, setDataExits] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const { car_id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    const getResult = async () => {
      const imgs = await axios.post(IMG_DESC_URL, { "car_id": car_id });
      ImageService.getImageURL(imgs.data)
        .then((cars) => {
          if (cars.length) {
            setImageDesc(cars);
            setIsLoading(false);
          } else {
            const defaultImgDesc = [{
              car_id: car_id,
              img_name: require('../../assets/1.png'),
              des: '',
            }];
            setImageDesc(defaultImgDesc);
            setIsLoading(false);
          }
        })
    }

    if (state) {
      const { data } = state;
      setShowingData({ ...data });
      getResult();
      setDataExits(true);
    } else {
      setShowingData({});
      setIsLoading(false);
    }
  }, [car_id]);

  const handleUpdate = useCallback((updatingData) => {
    const updateData = async () => {
      axios.post(USER_INFO_INSERT_URL, { name: updatingData.name, phone: updatingData.phone, address: updatingData.address, mail: updatingData.mail })
        .then((response) => {
          if (response.status === 200) {
            const date = Now();
            return axios.post(CAR_INFO_UPDATE_URL, {
              id: updatingData.car_id,
              maker: updatingData.maker,
              car_type: updatingData.carType,
              age: updatingData.age,
              mileage: updatingData.mileage,
              status: updatingData.status,
              update_date: date,
              register_date: updatingData.register_date,
            })
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log('succeded in updating');
            updatingShowingData();
          }
        })
        .catch((error) => {
          console.log(error);
          setError('更新に失敗しました。時間を置いて再度お試しください');
        });
    }

    const updatingShowingData = () => {
      let copiedData = showingData;
      copiedData['age'] = updatingData.age;
      copiedData['maker'] = updatingData.maker;
      copiedData['car_type'] = updatingData.carType;
      copiedData['mileage'] = updatingData.mileage;
      copiedData['status'] = updatingData.status;
      copiedData['name'] = updatingData.name;
      copiedData['phone'] = updatingData.phone;
      copiedData['address'] = updatingData.address;
      copiedData['mail'] = updatingData.mail;
      setShowingData({ ...copiedData });
      setIsEditing(isEditing => !isEditing);
    };

    updateData();

  }, [showingData]);

  const changeEditState = useCallback(() => {
    setIsEditing(isEditing => !isEditing);
  }, []);

  if (isLoading) {
    return (
      <Container>
        <Header />
        <Body>
          Loading....
        </Body>
      </Container>
    );
  } else if (!dataExists) {
    return (
      <Container>
        <Header />
        <Body>
          査定依頼車の情報がありません。
        </Body>
      </Container>
    );
  } else {
    return (
      <Container>
        <Header />
        <Body>
          <TitleContainer style={{ justifyContent: 'flex-start', marginLeft: 10, fontSize: TextSize.medium }}>
            査定依頼詳細
          </TitleContainer>
          <ItemDisplayStyle style={{ backgroundColor: Colors.bodyBackgroundColor }}>
            <ImageAndDescription imageDesc={imageDesc} />
            {
              isEditing ?
                <InfoDisplayStyle>
                  <EditCarInformation
                    data={showingData}
                    changeEditState={changeEditState}
                    setShowingData={setShowingData}
                    onChange={(updatingData) => handleUpdate(updatingData)}
                    setError={setError}
                    error={error}
                    isEditing={true}
                  />
                </InfoDisplayStyle>
                :
                <InfoDisplayStyle>
                  <CarInformation data={showingData} detailDataLabel={detailDataLabel} changeEditState={changeEditState} />
                </InfoDisplayStyle>
            }
          </ItemDisplayStyle>
        </Body>
        <Footer />
      </Container >
    );
  }
};

const ImageAndDescription = props => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { imageDesc } = props;
  const descriptions = imageDesc[selectedIndex].des ? imageDesc[selectedIndex].des.split(/\s/g) : [""]
  console.log(descriptions)
  return (
    <ImageDisplayStyle>
      <div style={{ height: 293, width: '98%', maxWidth: 398, aspectRatio: 0.75 }}>
        <a href={imageDesc[selectedIndex].img_name} target='_blank' rel='noopener'>
          <img src={imageDesc[selectedIndex].img_name} alt={'default image'} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
        </a>
      </div>
      <div style={{ width: '95%', paddingBottom: 10, marginTop: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {
          Object.keys(imageDesc).map((item, index) => {
            return (
              <div style={{ marginRight: 5, marginBottom: 5 }} onClick={() => setSelectedIndex(index)}>
                <img src={imageDesc[index].img_name} alt={'default image'} style={selectedIndex === index ? { height: 91, maxWidth: 128, width: '100%', objectFit: 'cover' } : { height: 94, maxWidth: 128, width: '100%', objectFit: 'cover' }} />
                {selectedIndex === index && <div style={{ height: 2, backgroundColor: 'skyblue', marginTop: 1, width: '100%', }} />}
              </div>
            )
          })
        }
      </div>
      <div style={{ width: '100%', height: '20%', minHeight: 100, fontSize: TextSize.small }}>
        {descriptions.map((desc) => {
          return (
            <div>
              {desc}
            </div>
          )
        })}
      </div>
    </ImageDisplayStyle>
  )
}

export const CarInformation = props => {
  const { data, detailDataLabel, changeEditState } = props;
  const history = useHistory();
  const [emailModalState, setEmailModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const { account_name } = useParams();

  const statusmap = new Map(statuses);
  const statusColor = new Map(StatusColor);

  const deleteCarData = async () => {
    await axios.post(CAR_INFO_DELTE_URL, { id: data.id })
      .then((response) => {
        if (response.status === 200) {
          console.log('succeded in deleting');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const deleteCarResistration = async () => {
    await deleteCarData();
    setDeleteModalState(false);
    history.push({ pathname: `/admin/${account_name}` });
  };

  const sendEmail = async () => {
    await EmailService.SendHtml([data.mail], data.name, account_name)
      .then(result => {
        if (result.status === 200) {
          const updatedStatus = 1;
          return axios.post(CAR_INFO_UPDATE_URL, { id: data.id, maker: data.maker, car_type: data.car_type, age: data.age, mileage: data.mileage, status: updatedStatus })
        }
      })
      .then((response) => {
        if (response.status === 200) {
          history.push({ pathname: `/admin/${account_name}` });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const HTMLMailButton = () => {
    if (data.status === 0 || data.status === 1) {
      return <Button onClick={() => setEmailModalState(true)}>メール送信</Button>;
    } else {
      return;
    }
  }

  const getDate = (value) => {
    const time = value.split('T');
    const date = time[0];
    const hour = time[1].split(':', 2).join(':');
    const dateTime = date + ' ' + hour;
    return dateTime;
  }

  return (
    <>
      <EmailModal emailModalState={emailModalState} sendEmail={sendEmail} setEmailModalState={setEmailModalState} />
      <DeleteModal deleteModalState={deleteModalState} deleteCarResistration={deleteCarResistration} setDeleteModalState={setDeleteModalState} />
      <InfoButtonDisplayStyle>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button onClick={() => changeEditState()}>編集</Button>
          <div style={{ width: 4 }} />
          <Button buttonStyle={'delete'} onClick={() => setDeleteModalState(true)}>削除</Button>
        </div>
        {HTMLMailButton()} {/* if status !== 1, then appears */}
      </InfoButtonDisplayStyle>
      <div style={{ width: '100%', height: '90%', overflowY: 'auto', marginTop: 15 }}>
        <LabelInfoStyle>
          <div style={{ fontSize: TextSize.small, width: 120, color: Colors.labelColor }}>
            {'依頼日時'}
          </div>
          <div style={{ fontSize: TextSize.small, overflowX: 'auto' }}>
            {getDate(data['create_date'])}
          </div>
        </LabelInfoStyle>
        <LabelInfoStyle>
          <div style={{ fontSize: TextSize.small, width: 120, color: Colors.labelColor }}>
            {'ステータス'}
          </div>
          <div style={{ fontSize: TextSize.small, minWidth: 84, color: 'white', padding: '3px 20px 3px 20px', backgroundColor: `${statusColor.get(data['status'])}`, textAlign: 'center', borderRadius: 5 }}>
            {statusmap.get(data['status'])}
          </div>
        </LabelInfoStyle>
        <div style={{ height: 20 }} />
        {
          Object.keys(data).map((item, index) => {
            if (detailDataLabel[item]) {
              let label = data[item];
              if (item === 'status') {
                return;
              } else if (item === 'name') {
                return (
                  <LabelInfoStyle style={{ marginTop: 20 }}>
                    <div style={{ fontSize: TextSize.small, width: 120, color: Colors.labelColor }}>
                      {detailDataLabel[item]}
                    </div>
                    <div style={{ fontSize: TextSize.small, overflowX: 'auto' }}>
                      {label}
                    </div>
                  </LabelInfoStyle>
                )
              }
              return (
                <LabelInfoStyle>
                  <div style={{ fontSize: TextSize.small, width: 120, color: Colors.labelColor }}>
                    {detailDataLabel[item]}
                  </div>
                  <div style={{ fontSize: TextSize.small, overflowX: 'auto' }}>
                    {label}
                  </div>
                </LabelInfoStyle>
              )
            }
          })
        }
      </div>
    </>
  )
}

const LabelInfoStyle = styled.div`
  height: auto;
  width: 100%;
  margin-bottom: 3px;
  padding: 5px;
  display: flex;
  flex-direction: row;
`


export default CarDetail;