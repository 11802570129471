import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable'
import Ajv from 'ajv';
import styled from 'styled-components';
import { errorMessages, Colors, TextSize } from '../../config';
//components
import Title from '../../Components/Title';
import SubmitButton from '../../Components/SubmitButton';
import { CustomerContainer } from '../../Components/StyledComponents';
import InputProgressBar from '../../Components/InputProgressBar';
//functions
import { getMakerOptions, getCarTypeOptions, getAgeOptions, getWarekiOptions, getMileageOptions } from '../../Functions';

const Input = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [maker, setMaker] = useState('');
  const [carType, setCarType] = useState('');
  const [age, setAge] = useState('');
  const [mileage, setMileage] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [mail, setMail] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [carId, setCarId] = useState();
  const [checked, setChecked] = useState('西暦');
  const [makerOptions, setMakerOptions] = useState([]);
  const [carTypeOptions, setCarTypeOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [mileageOptions, setMileageOptions] = useState([]);
  const [carImageData, setCarImageData] = useState([]);
  const [error, setError] = useState('');
  const { account_name } = useParams();

  // if user went back to this page from next page
  useEffect(() => {
    if (state) {
      const { generalInfo, carImages } = state; // fetch previous input info
      setMaker(generalInfo.maker);
      setCarType(generalInfo.carType);
      setAge(generalInfo.age);
      setMileage(generalInfo.mileage);
      setName(generalInfo.name);
      setPhone(generalInfo.phone);
      setAddress(generalInfo.address);
      setMail(generalInfo.mail);
      setIsUpdate(generalInfo.isUpdate);
      setCarId(generalInfo.carId);
      //for photos
      setCarImageData(carImages);
    } else {
      history.push({
        pathname: `/user/${account_name}`,
      });
    }
  }, [state])


  useEffect(() => {
    const ageOptions = getAgeOptions();
    setAgeOptions(ageOptions);

    const miliageOptions = getMileageOptions();
    setMileageOptions(miliageOptions);

    const getMakers = async () => {
      const makerOptions = await getMakerOptions();
      setMakerOptions(makerOptions);
    }

    getMakers();
  }, []);

  useEffect(() => {
    const getCarTypes = async () => {
      setCarTypeOptions([]);
      const carTypeOptions = await getCarTypeOptions(maker);
      setCarTypeOptions(carTypeOptions);
    };

    getCarTypes();
  }, [maker]);

  const onChangeMaker = (e) => {
    if (!e) {
      setMaker('');
    } else {
      setMaker(e.value);
    }

    setCarType('');
  };

  const onChangeCarType = (e) => {
    if (!e) {
      setCarType('');
    } else {
      setCarType(e.value);
    }
  };

  const onChangeAge = (e) => {
    if (!e) {
      setAge('');
    } else {
      setAge(e.value);
    }
  };

  const onChangeMileage = (e) => {
    if (!e) {
      setMileage('');
    } else {
      setMileage(e.value);
    }
  };

  const onChecked = (e) => {

    if (e === '西暦') {
      const ageOptions = getAgeOptions();
      setAgeOptions(ageOptions);
    } else {
      const warekiOptions = getWarekiOptions();
      setAgeOptions(warekiOptions);
    }

    setChecked(e);
  };

  const checkForm = useCallback(() => {
    const ajv = Ajv({ allErrors: true });
    const errorMessagesMap = new Map(errorMessages);
    const errorMessage = [];

    const data = {
      maker: maker ? maker : undefined,
      carType: carType ? carType : undefined,
      age: age ? age : undefined,
      mileage: mileage ? mileage : undefined,
      name: name ? name : undefined,
      phone: phone ? phone : undefined,
      address: address ? address : undefined,
      mail: mail,
    }

    const schema = {
      required: ['maker', 'carType', 'age', 'mileage', 'name', 'phone', 'address', 'mail'],
      type: 'object',
      properties: {
        mail: {
          type: 'string',
          format: 'email',
        },
      },
    };

    const validate = ajv.compile(schema);
    const valid = validate(data);

    if (!valid) {
      console.log(validate.errors);
      validate.errors.forEach(error => {
        if (error.keyword === 'required') {
          errorMessage.push(errorMessagesMap.get(error.params.missingProperty) + '\n');
        } else if (error.keyword === 'format') {
          errorMessage.push(`メールアドレスのフォーマットが正しくありません。\n`)
        }
      })

      setError(errorMessage);
      return;
    }
    history.replace({
      pathname: history.location.pathname,
      state: {
        generalInfo: {
          maker: maker,
          carType: carType,
          age: age,
          mileage: mileage,
          name: name,
          phone: phone,
          address: address,
          mail: mail,
          isUpdate: isUpdate,
          carId: carId,
        },
        carImages: carImageData
      }
    });

    history.push({
      pathname: `/user/${account_name}/photos`,
      state: {
        generalInfo: {
          maker: maker,
          carType: carType,
          age: age,
          mileage: mileage,
          name: name,
          phone: phone,
          address: address,
          mail: mail,
          isUpdate: isUpdate,
          carId: carId,
        },
        carImages: carImageData,
      }
    });
  }, [history, maker, carType, age, mileage, name, phone, address, mail, isUpdate, carId]);

  return (
    <CustomerContainer>
      {error && (
        <div style={{ whiteSpace: 'pre-line' }}>
          <Alert variant="danger">{error}</Alert>
        </div>
      )}
      <InputProgressBar currentIndex={0} />
      <div style={{ marginTop: '8%' }} />
      <Title titleText='査定の情報を入力してください。' />
      <div style={{ marginTop: '8%' }} />
      <div style={{ width: '80%', maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }}>
        <div style={selectionContainerStyle}>
          <label style={labelStyle}>メーカー</label>
          <CreatableSelect value={maker ? [{ label: maker, value: maker }] : null} isClearable={true} isSearchable={true} options={makerOptions}
            onChange={(value) => onChangeMaker(value)} className={'top-selection'} />
        </div>
        <div style={selectionContainerStyle}>
          <label style={labelStyle}>車種</label>
          <CreatableSelect value={carType ? [{ label: carType, value: carType }] : null} isClearable={true} isSearchable={true} options={carTypeOptions}
            onChange={(value) => onChangeCarType(value)} className={'top-selection'} />
        </div>
        <div style={selectionContainerStyle}>
          <div style={subSelectionContainerStyle}>
            <label style={labelStyle}>年式</label>
            <label style={labelStyle}>西暦</label>
            <input type="radio" name="radio" value={'西暦'} checked={checked === '西暦'} onChange={(e) => onChecked(e.target.value)} />
            <label style={labelStyle}>和暦</label>
            <input type="radio" name="radio" value={'和暦'} checked={checked === '和暦'} onChange={(e) => onChecked(e.target.value)} />
          </div>
          <CreatableSelect value={age ? [{ label: age, value: age }] : null} isClearable={true} isSearchable={true} options={ageOptions}
            onChange={(value) => onChangeAge(value)} className={'top-selection'} />
        </div>
        <div style={selectionContainerStyle}>
          <label style={labelStyle}>走行距離</label>
          <CreatableSelect value={mileage ? [{ label: mileage, value: mileage }] : null} isClearable={true} isSearchable={true} options={mileageOptions}
            onChange={(value) => onChangeMileage(value)} className={'top-selection'} />
        </div>
        <div style={{ marginTop: '14%' }} />
        <div>
          <label style={labelStyle}>氏名</label>
          <CustomerInput placeholder={'山田　太郎'} defaultValue={name} type="text" name="name" onBlur={(e) => setName(e.target.value)} />
        </div>
        <div>
          <label style={labelStyle}>電話番号</label>
          <CustomerInput placeholder={'電話番号'} defaultValue={phone} type="tel" name="phone" onBlur={(e) => setPhone(e.target.value)} readOnly={true} />
        </div>
        <div>
          <label style={labelStyle}>住所</label>
          <CustomerInput placeholder={'東京都江東区'} defaultValue={address} type="text" name="address" onBlur={(e) => setAddress(e.target.value)} />
        </div>
        <div>
          <label style={labelStyle}>メールアドレス</label>
          <CustomerInput placeholder={'mail@example.com'} defaultValue={mail} type="text" name="mail" onBlur={(e) => setMail(e.target.value)} />
        </div>
      </div>
      <div style={{ marginTop: '10%' }} />
      <SubmitButton buttonText='続ける' onClick={() => checkForm()} isEnabled={true} />
      <div style={{ marginTop: '20%' }} />
    </CustomerContainer >
  );
};

const selectionContainerStyle = { width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 8 };
const subSelectionContainerStyle = { width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center' };
const labelStyle = { fontSize: TextSize.small, width: '100%', paddingLeft: 4, marginBottom: -2 };
const CustomerInput = styled.input`
  font-size: 14px;
  width: 100%;
  height: 35px;
  max-width: 300px;
  border-radius: 3px;
  border: 1px solid lightgray;
  outline-color: ${Colors.enable};
  padding-left: 4px; 
  ::placeholder {
    color: lightgray
  }
`

export default Input;