import styled from 'styled-components';
import { TextSize, Colors } from "../config";

export const Container = styled.div`
width: 100vw;
min-height: 100%;
height: auto;
background-color: ${Colors.background};
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

export const HeaderStyle = styled.nav`
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: white;
  align-items: center;
  box-shadow: 1px 1px 5px #BFBFBF;
  @media screen and (max-width: 620px) {
    height: 60px;
  }
`
export const HeaderLogo = styled.div`
  margin-left: 30px;
  width: 55px;
  height: 70%;
  background-color: #D2D5D7;
  text-align: center;
  @media screen and (max-width: 620px) {
    width: calc(60px * 0.7);
    margin-left: calc(10px + 1vw);
  }
`

export const HeaderTitle = styled.div`
  margin-left: 20px;
  text-align: center;
  font-weight: 600; 
  font-size: ${TextSize.medium};
`

export const HeaderIcons = styled.div`
  position: fixed;
  right: 1.3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 620px) {
    right: 10px;
  }
`

export const SideBarContent = styled.div`
  background-color: white;
  height: 100%;
  width: 15vw;
  font-size: calc(10px + 1vw);
  position: fixed;
  top: 0;
  z-index: 0;
  display: flex;
  margin-top: 82px;
  background-clip: padding-box;
  @media screen and (max-width: 620px) {
    display: none;
  }
`
export const SideBarItemsContiner = styled.div`
  width: 100%;
  font-size: 15px;
  justify-content: center;
  padding: 0px 0px 6px 5px;
`

export const SideBarItem = styled.li`
  height: 30px;
  list-style: none;
  padding: 8px 5px 8px 10px;
  z-index: 1;
`

export const SideBarIcon = styled.div`
  display: none;
  @media screen and (max-width: 620px) {
    display: flex;
    position: absolute;
    top: 15px;
    left: 10px;
  }
`

export const TitleContainer = styled.div`
  display: felx;
  flex-direction: column;
  with: 95%;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: ${TextSize.large};
  @media screen and (max-width: 620px) {
    margin-top: 5px;
  }
`

export const MasterContainer = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  @media screen and (max-width: 620px) {
    flex-direction: column;
    justify-content: none;
  }
`

export const MasterItem = styled.div`
  display: flex;
  flex-direction:row;
  @media screen and (max-width: 620px) {
    width: 80%;
    align-self: center;
    justify-content: space-around;
    margin-bottom: 10px;
  }
`

export const Body = styled.div`
  width: 95%;
  max-width:1300px;
  margin-top: 120px;
  font-size: calc(10px + 1vw);
  justify-self: center;
  z-index: 0;
  margin: aout;
  @media screen and (max-width: 620px) {
    margin-top: 80px;
    width: 95%;
    padding-right: 0px;
    padding-left: 0px;
  }
`

export const BodyElement = styled.div`
  width: 100%;
  height: 70vh;
  background-color: white;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    height: 140vh;
    flex-direction: column;
    justify-content: center;
  }
`

export const BodyItem = styled.div`
  height:100%;
  width:50%;
  padding: 30px;
  text-align: center;
  display: flex; 
  flex-direction: column; 
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 50%;
  }
`

export const FooterStyle = styled.div`
  background-color: white;
  height: 100%
  min-height: 132px;
  width: 100%;
  margin-top: 100px; 
  display: flex;
  flex-direction: row;
  padding: 20px;
  // box-shadow: 1px -1px 5px #BFBFBF;
  border-top: solid 2px black;
  @media screen and (max-width: 620px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
  }
`

export const FooterColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 620px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
  }
`
export const FooterItems = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 620px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
  }
`

export const SearchContainerStyle = styled.div`
  width: 60%;
  min-width: 300px;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 620px) {
    width: 90%;
    align-self: center;
    justify-content: space-around;
    margin-bottom: 10px;
  }
`
export const SearchBoxStyle = styled.div`
  background-color: white;
  height: 30px;
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-right: 10px;
  border: solid 2px black;
  @media screen and (max-width: 620px) {
    width: 90%;
    height: 25px;
  }
`

export const SearchInput = styled.input`
  // width: 40%;
  width: 90%;
  max-width: 300px;
  height: 100%;
  padding-left: 10px;
  align-self: center;
  border-width:0px;
  border:0px;
  outline:0px;
  font-size: ${TextSize.small};
`

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
}

export const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const DropDownStyle = styled.div`
  position: absolute;
  top: 40px;
  right: -100px;
  width: 200px;
  transform: translateX(-45%);
  background-color: white;
  align-items: center;
  box-shadow: 1px 1px 5px #BFBFBF;
  padding: 1rem;
  overflow: hidden;

`

export const DropDownItemStyle = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  transition: background-color 300ms;
  &:hover {
    background-color: rgba(250,250,250,1);
  } 
`

export const CustomerContainer = styled.div`
  width: 100%;
  z-index: 0;
  overflow-y: scroll;
  background-color: white;
`

export const LoginStyle = styled.div`
  width: 300px;
  height: 60%;
  background-color: white;
  margin: auto;
  text-align: center;
  justify-content: center;
`

export const ItemDisplayStyle = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 10px;
  @media screen and (max-width: 620px) {
    flex-direction: column;
    padding-top: 10px;
  }
`

export const ImageDisplayStyle = styled.div`
  width: 45%;
  height: 100%;
  padding:64px 0 0 24px;
  @media screen and (max-width: 620px) {
    padding-left: 0px;
    padding-top: 20px;
    width: 100%;
    margin: auto;
  }
`

export const InfoDisplayStyle = styled.div`
  width: 55%;
  height: 100%;
  @media screen and (max-width: 620px) {
    width: 90%;
    margin: auto;
    text-align: center;
  }
`

export const InfoButtonDisplayStyle = styled.div`
  height: 10%;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 5px 5px 5px;
  @media screen and (max-width: 620px) {
    text-align: center;
  }
`

export const CardListStyle = styled.div`
  width: 100%;
  height: 120px;
  background-color: white;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px lightgray;
  padding: 5px 0 5px 0;
  @media screen and (max-width: 620px) {
    flex-direction: column;
    height: auto;
  }
`

export const ListItemInfoStyle = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100% - 120px);
  align-self: center;
  border-right: solid 1px lightgray;
  @media screen and (max-width: 620px) {
    width: 100%;
    margin-bottom: 10px;
    border-right: none;
  }
`

export const ListImageStyle = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: 620px) {
    height: 70%;
    object-fit: cover;
  }
`

export const ListItemButtonStyle = styled.div`
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 620px) {
    width: 100%;
  }
`

export const TableStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 45px;
  @media screen and (max-width: 620px) {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
`

export const TableTitle = styled.div`
  width: 30%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  @media screen and (max-width: 620px) {
    width: 100%;
    margin-bottom: 5px;
  }
`
export const EditTableContentInput = styled.input`
  width: 60%;
  @media screen and (max-width: 620px) {
    width: 95%;
  }
`