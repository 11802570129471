import React from 'react';
import { Colors } from '../config';

const SubmitButton = (props) => {
  const { buttonText, onClick, isEnabled } = props;
  const containerStyle = isEnabled ? { ...container, backgroundColor: Colors.customerBtnEnabled } : { ...container, backgroundColor: Colors.customerBtnDisabled };

  return (
    <div style={{ textAlign: 'center', alignItems: 'center' }}>
      <button onClick={onClick} style={containerStyle} disabled={!isEnabled}>{buttonText}</button>
    </div>
  )
};

const container = {
  width: '75%',
  maxWidth: 300,
  height: 30,
  padding: 3,
  fontSize: 12,
  border: 'none',
  margin: 'auto',
  color: 'white',
}

export default SubmitButton;