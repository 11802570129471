import React, { useCallback, useEffect, useState } from 'react';
import { FormLabel, FormGroup, FormControlLabel, Checkbox, Popover } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { statuses } from '../config';
import { SearchInput, SearchContainerStyle, SearchBoxStyle } from "./StyledComponents";
import { ButtonStyle } from './Button';

const SearchBar = (props) => {
  const { setSearchingData, carData } = props;
  const [searchInput, setSerchInput] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { height, width } = useWindowDimensions();


  useEffect(() => {
    statuses.forEach((item) => {
      checkedValues.push(item[0]);
    })
    createStatusOptions();
  }, []);


  useEffect(() => {
    const filteredData = queryData(searchInput, carData);
    setSearchingData(filteredData);
  }, [carData, searchInput]);

  const createStatusOptions = () => {
    const statusOptions = statuses.map(
      (item) => (
        <FormControlLabel key={item[0]}
          control={< Checkbox onChange={() => { onCheck(item[0]); }} checked={checkedValues.filter(v => v === item[0]).length > 0} value={item[0]} size="small" color="primary" />}
          label={<span className={'menu-checkbox'}>{item[1]}</span>}
        />
      )
    );

    setStatusOptions(statusOptions);
  }

  const queryData = (text, carData) => {
    const searchQuery = text.toLowerCase();
    const filteredData = [];
    const filteredCars = [];

    // ステータスでフィルタ
    checkedValues.forEach(item => filteredCars.push(carData.filter(v => v.status === item)));
    filteredCars.forEach(cars => cars.map(car => filteredData.push(car)));

    // 検索ワードでフィルタ、降順でソート
    const filterItems = filteredData.filter(value =>
      (value.maker.toLowerCase().includes(searchQuery) ||
        value.maker.toLowerCase().includes(searchQuery) ||
        value.car_type.toLowerCase().includes(searchQuery) ||
        value.name.toLowerCase().includes(searchQuery) ||
        value.address.toLowerCase().includes(searchQuery))).sort((a, b) => (b.id - a.id));

    return filterItems;
  };

  const onCheck = useCallback((item) => {
    const checked = checkedValues;

    checked.filter(v => v === item).length > 0
      ? checked.splice(checked.findIndex(v => v === item), 1)
      : checked.push(item);

    setCheckedValues(checked);
    createStatusOptions();

    const filteredData = queryData(searchInput, carData);
    setSearchingData(filteredData);
  }, [carData, searchInput, checkedValues]);

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  return (
    <SearchContainerStyle>
      <SearchBoxStyle>
        <SearchIcon />
        <SearchInput placeholder={'メーカー、車名、名前など'} onChange={(e) => setSerchInput(e.target.value)} />
      </SearchBoxStyle>

      {
        width >= 620 ?
          <ButtonStyle onClick={handleMenuOpen}>
            <MenuIcon style={{ fontSize: 16, marginRight: 4 }} className="icon-menu" />
            ステータス絞り込み
          </ButtonStyle>
          :
          <MenuIcon fontSize="default" className="icon-menu" onClick={handleMenuOpen} />
      }

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          width >= 620 ?
            null
            :
            <FormLabel>
              ステータス絞り込み
            </FormLabel>
        }

        <FormGroup>
          {statusOptions}
        </FormGroup>
      </Popover>
    </SearchContainerStyle>
  )
}


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default SearchBar;