import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Ajv from 'ajv';
//components
import SubmitButton from '../../Components/SubmitButton';
import Title from '../../Components/Title';
import GridView from '../../Components/GridView';
import InputProgressBar from '../../Components/InputProgressBar';
//forms
import { carImageData, requiredImageIndex } from '../../Forms';

const Photos = props => {
  const history = useHistory();
  const { state } = useLocation();
  const { generalInfo, carImages } = state;
  const [carImageDataAndDescription, setCarImageDataAndDescription] = useState(carImageData);
  const [sendingInfo, setSendingInfo] = useState({});
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const { account_name } = useParams();

  useEffect(() => {
    if (carImages) {
      setCarImageDataAndDescription(carImages);
    }
  }, [state]);

  const checkPhotos = useCallback(() => {
    const filledForm = { generalInfo: generalInfo, carImages: carImageDataAndDescription };
    const imageFiles = carImageDataAndDescription.map((item) => item.imageFile);
    const err = imageValidation(imageFiles);
    if (err) {
      setError(err);
    } else {
      setError('');
      setFiles(imageFiles);
      setSendingInfo(filledForm);
    }
  }, [generalInfo, carImageDataAndDescription]);

  useEffect(() => {
    // navigate to confirm screen
    if (Object.keys(sendingInfo).length) {
      history.replace({
        pathname: history.location.pathname,
        state: { generalInfo: sendingInfo.generalInfo, carImages: sendingInfo.carImages }
      })

      history.push({
        pathname: `/user/${account_name}/confirm`,
        state: { generalInfo: sendingInfo.generalInfo, carImages: sendingInfo.carImages },
      });
    }
  }, [sendingInfo, files]);

  const backToTop = () => {
    history.push({
      pathname: `/user/${account_name}/input`,
      state: { generalInfo: generalInfo, carImages: carImageDataAndDescription },
    });
  };

  const imageValidation = (imageFiles) => {
    const missingRequiredImageIndex = [];
    requiredImageIndex.map((index) => !imageFiles[index] && missingRequiredImageIndex.push(index));

    if (missingRequiredImageIndex.length > 0) {
      return '必須の画像をセットしてください。'
    }
    return null;
  };

  return (
    <div className={wrapper}>
      <InputProgressBar currentIndex={1} />
      <div style={{ marginTop: '18%' }} />
      <Title titleText={'お車の写真を撮影してください。'} />
      <Title titleText={'必須項目は4点です！'} />
      <div style={{ marginTop: '3%' }} />
      <GridView
        carImageDataAndDescription={carImageDataAndDescription}
        setCarImageDataAndDescription={setCarImageDataAndDescription}
        allowEditing={true}
      />
      <div style={{ marginTop: '3%' }} />
      {error && (
        <div style={{ whiteSpace: 'pre-line' }}>
          <Alert variant="danger">{error}</Alert>
          <div style={{ marginTop: '3%' }} />
        </div>
      )}
      <SubmitButton buttonText='続ける' onClick={() => checkPhotos()} isEnabled={true} />
      <div style={{ marginBottom: '10%' }} />
    </div>
  );
};

export const wrapper = {
  width: '100%',
  height: '100%',
  overflow: 'scroll',
  backgroundColor: '#F9F9F9'
};

export default Photos;