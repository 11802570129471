// ドメイン名
export const DOMAIN_NAME = 'satecon.jp';

// API接続先
let URL = 'http://localhost:8081';
if (process.env.NODE_ENV === 'production') {
    URL = `https://${DOMAIN_NAME}:8443`;
}
export const API_URL = URL;
// export const API_URL = 'http://localhost:8081';
// export const API_URL = `https://${DOMAIN_NAME}:${PORT}`;

export const SERVICE_NAME = 'スマホで査定　サテコン';
export const SENDER_ADDRESS = 'info@satecon.jp';

export const AUTH_URL = API_URL + '/auth';
export const EMAIL_URL = API_URL + '/email';
export const INSERT_URL = API_URL + '/insert';
export const UPDATE_URL = API_URL + '/update';
export const UPLOAD_URL = API_URL + '/upload';
export const FETCH_URL = API_URL + '/fetch';
export const USER_INFO_INSERT_URL = API_URL + '/insert/user_info'; // ユーザー情報のインサート（電話番号が登録済みの場合はアップデート） method: POST, name, phone, address, mail
export const FETCH_STORE_URL = API_URL + '/fetch/store_info'; //アカウント設定（account_name, password）のアップデート method: POST, body id
export const DATA_LIST_URL = API_URL + '/fetch/data_list'; // 査定依頼車、ユーザー、その画像（一枚, ファイルフォーマット）フェッチしてくる　method: GET
export const IMG_DESC_URL = API_URL + '/fetch/car_img_desc'; // 車の写真とその詳細文をフェッチしてくる。 詳細ページで使う。　method: POST, body: car_id
export const SIGNED_URL = API_URL + '/signedurl/?filename='; // 著名付きURLを取得してくる
export const STATUS_UPDATE_URL = API_URL + '/update/car_status'; // 査定依頼車のステータスアップデート method: POST, body" id(car_id), status([number])
export const CAR_INFO_UPDATE_URL = API_URL + '/update/car_info'; // 査定依頼車の詳細アップデート　method: POST, body id, maker, car_type, age, mileage, status
export const STORE_INFO_UPDATE_URL = API_URL + '/update/store_info'; // 店舗情報のアップデート　method: POST, body id, name, address, phone, mail, logo_image
export const CAR_INFO_UID_UPDATE_URL = API_URL + '/update/car_info/account_name'; // 査定依頼車(account_name)のアップデート method: POST, body: before_account_name, after_account_name
export const STORE_UID_UPDATE_URL = API_URL + '/update/store_info/account_name'; // 店舗アカウント情報(account_name)のアップデート method: POST, body: account_name, id
export const STORE_PASSWORD_UPDATE_URL = API_URL + '/update/store_info/password'; // 店舗アカウント情報(password)のアップデート　method: POST, body: password, id
export const CAR_INFO_DELTE_URL = API_URL + '/delete/car_info'; // 査定依頼者の消去　method: POST, body id;

export const detailDataLabel = { car_type: '車名', maker: 'メーカー', mileage: '走行距離', age: '年式', status: 'ステータス', name: '名前', address: '住所', mail: 'メールアドレス', phone: '電話番号' };
export const registerForm = { maker: null, car_type: null, age: null, mileage: null, status: 0, name: null, phone: null, mail: null };

export const mileages = [
    '〜5000',
    '5001〜10000',
    '10001〜15000',
    '15001〜20000',
    '20001〜25000',
    '25001〜30000',
    '30001〜35000',
    '35001〜40000',
    '40001〜45000',
    '45001〜50000',
    '50001〜55000',
    '55001〜60000',
    '60001〜65000',
    '65001〜70000',
    '70001〜75000',
    '75001〜80000',
    '80001〜85000',
    '85001〜90000',
    '90001〜95000',
    '95001〜100000',
    '100001〜105000',
    '100001〜105000',
    '100001〜105000',
    '100001〜105000',
    '100001〜110000',
    '110001〜115000',
    '115001〜120000',
    '120001〜125000',
    '125001〜130000',
    '130001〜135000',
    '135001〜140000',
    '140001〜145000',
    '145001〜150000',
    '150001〜',
];

export const statuses = [
    [0, '新規登録'],
    [1, 'メール送信済み'],
    [2, '査定依頼有り'],
    [3, '査定中'],
    [4, '成約'],
    [5, 'キャンセル'],
];

export const errorMessages = [
    ['maker', 'メーカーを選択してください。'],
    ['carType', '適切な車種を選択してください。'],
    ['age', '年式を選択してください。'],
    ['mileage', '走行距離を記入してください。'],
    ['name', '名前を記入してください。'],
    ['phone', '電話番号を記入してください。'],
    ['address', '住所を記入してください。'],
    ['mail', 'メールアドレスを記入してください。'],
];

export const Colors = {
    background: '#FFFFFF',
    bodyBackgroundColor: '#FAFAFA',
    delete: '#CC6464',
    enable: '#000000',
    btnHoverColor: '#999999',
    cancelBtnColor: '#CCCCCC',
    required: '#FF5A5F',
    error: 'red',
    labelColor: 'gray',
    customerBtnEnabled: '#64B8CC',
    customerBtnDisabled: 'gray',
    customerInputProgressed: 'black',
    customerInputUnprogressed: '#D9D9D9'
}

export const TextSize = {
    large: 'min(24px, max(20px, 1.5vw))',
    medium: 'min(20px, max(16px, 1.2vw))',
    small: 'min(14px, max(10px, 0.9vw))',
}

export const StatusColor = [
    [0, '#6494CC'], //新規登録
    [1, '#CC6464'], //査定依頼有り
    [2, '#CCBC64'], //査定中
    [3, '#CCBC64'], //成約
    [4, '#C7C7C7'], //キャンセル
    [5, '#64B8CC'], //メール送信済み
]

export const exampleCSVdata = [
    {
        氏名: '山田太郎',
        電話: '080-1010-2020',
        メール: 'example1@gmail.com',
        住所: '栃木県那須塩原市',
        メーカー: 'トヨタ',
        車種: 'カローラ',
        年式: 2010,
        走行距離: '10000',
    },
    {
        氏名: '山田次郎',
        電話: '080-2020-3030',
        メール: 'example2@gmail.com',
        住所: '栃木県那須塩原市',
        メーカー: 'トヨタ',
        車種: 'カローラ',
        年式: 2012,
        走行距離: '10000',
    }
]