import React, { useCallback } from 'react';
import { Colors } from '../config';


const InputProgressBar = (props) => {

  const { currentIndex } = props;
  const textList = ['査定情報入力', '写真入力', '完了'];

  const getProgress = (text, index) => {
    let style = { ...progressStyle };
    if (index <= currentIndex) { // color upto current progress
      style = { ...style, backgroundColor: Colors.customerInputProgressed };
      return (
        <div style={progressContainer}>
          <div style={style}>
            {text}
          </div>
          {
            index === currentIndex ?
              <div style={{ height: 1, width: '100%', backgroundColor: '#4FB6D2', marginTop: 1 }} />
              :
              null
          }
        </div>
      )
    } else { // color gray for next progress
      style = { ...style, backgroundColor: Colors.customerInputUnprogressed };
      return (
        <div style={progressContainer}>
          <div style={style}>
            {text}
          </div>
          {
            index === currentIndex ?
              <div style={{ height: 1, width: '100%', backgroundColor: '#4FB6D2' }} />
              :
              null
          }
        </div>
      )
    }
  }

  return (
    <div style={barStyle}>
      {
        textList.map((item, index) => {
          return getProgress(item, index);
        })
      }
    </div>
  );
};


const barStyle = {
  height: 30,
  width: '85%',
  maxWidth: 300,
  display: 'flex',
  flexDirection: 'row',
  margin: '10% auto 10% auto',
  fontSize: 10,
  color: 'white',
  justifyContent: 'center',
  alignSelf: 'center'
}
const progressContainer = {
  height: '100%',
  width: '30%',
  display: 'flex',
  flexDirection: 'column',
  marginRight: 1
}

const progressStyle = {
  height: 28,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const underLineStyle = {

}

export default InputProgressBar