import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
//componetns
import Title from '../../Components/Title';
import GridView from '../../Components/GridView';
import SubmitButton from '../../Components/SubmitButton';
import { carFormList, profileFormList } from '../../Forms';
import { CustomerContainer } from '../../Components/StyledComponents';
import InputProgressBar from '../../Components/InputProgressBar';
//api
import { INSERT_URL, UPDATE_URL, UPLOAD_URL, FETCH_URL } from "../../config.js";
//services
import EmailService from '../../Services/EmailService';
//functions
import { Now } from '../../Functions';
//styles
import { wrapper } from './Photos';

const Confirm = props => {
  const history = useHistory();
  const { state } = useLocation();
  const { generalInfo, carImages } = state;
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [showingImageData, setShowingImageData] = useState([]);
  const { account_name } = useParams();
  const uploadedImages = [];

  useEffect(() => {
    if (Object.keys(generalInfo).length) {
      readyDispayImage()
        .then((carImage) => {
          setShowingImageData(carImage);
          setIsLoading(false);
        });
    }
  }, [generalInfo]);


  const readyDispayImage = () => {
    return new Promise((resolve) => {
      const carImageData = [];
      carImages && Object.keys(carImages).map((item) => {
        const car = carImages[item];
        if (car.image) {
          const carImage = { partName: car.partName, image: car.image, description: car.description, imageFile: car.imageFile };
          carImageData.push(carImage);
        }
      });
      resolve(carImageData);
    });
  }

  const handleUploadImage = async () => {
    const result = await uploadImage(carImages);
    for (let i = 0; i < result.length; i++) {
      const carImage = carImages.find(v => v.partName === result[i].fieldname);
      if (carImage) {
        uploadedImages.push({ key: result[i].key, description: carImage.description });
      }
    }
  }

  const uploadImage = async (carImages) => {
    const params = new FormData();
    for (let i = 0; i < carImages.length; i++) {
      if (carImages[i].imageFile) {
        params.append(carImages[i].partName, carImages[i].imageFile);
      }
    }

    return (await axios.post(UPLOAD_URL, params,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })).data;
  }

  const onSubmit = async (e) => {
    // e.preventDefault();
    setIsSending(true);

    try {
      await handleUploadImage();

      const resultUserInfo = await axios.post(INSERT_URL + "/user_info",
        {
          name: generalInfo.name,
          phone: generalInfo.phone,
          address: generalInfo.address,
          mail: generalInfo.mail
        });

      let car_id = 0;
      const date = Now();
      if (generalInfo.isUpdate) {
        // 更新の場合、UPDATE
        const resultCarInfo = await axios.post(UPDATE_URL + "/car_info",
          {
            maker: generalInfo.maker,
            car_type: generalInfo.carType,
            age: generalInfo.age,
            mileage: generalInfo.mileage,
            user_id: resultUserInfo.data.insertId,
            status: 2,
            account_name: account_name,
            update_date: date,
            register_date: date,
            id: generalInfo.carId,
          });

        car_id = generalInfo.carId;
      } else {
        // 新規の場合、INSERT
        const resultCarInfo = await axios.post(INSERT_URL + "/car_info",
          {
            maker: generalInfo.maker,
            car_type: generalInfo.carType,
            age: generalInfo.age,
            mileage: generalInfo.mileage,
            user_id: resultUserInfo.data.insertId,
            status: 2,
            account_name: account_name,
            create_date: date,
            update_date: date,
            register_date: date,
          });

        car_id = resultCarInfo.data.insertId;
      }

      await axios.post(INSERT_URL + "/img_des",
        {
          images: uploadedImages,
          car_id: car_id
        });

      await EmailService.SendTextCustomer([generalInfo.mail], generalInfo.name, car_id);
      await axios.get(FETCH_URL + `/store_mail?account_name=${account_name}`)
        .then((response) => {
          if (response.status === 200) {
            return response.data[0];
          }
        })
        .then((result) => {
          EmailService.SendTextStore([result.mail]);
        })
        .catch((error) => {
          console.log('error', error)
        });
        
      setIsSending(false);
      history.push({
        pathname: `/user/${account_name}/submit`,
        state: {
          contactNumber: car_id
        }
      });
    } catch (e) {
      // error handling
      console.log(e);
    }
  }

  const backToTop = () => {
    history.go(-1);
  };

  const filledFormList = (formList) => {
    return Object.keys(formList).map((label, key) =>
      <li key={key.toString()} style={{ padding: 6, width: '80%', display: 'flex', flexDirection: 'column', marginLeft: '8vw' }} >
        <div style={labelContainer}>
          {formList[label]}
        </div>
        <div style={filledFormContainer}>
          {generalInfo[label]}
        </div>
      </li >
    );
  }

  if (isLoading) {
    return (
      <div>
        <div style={{ marginTop: '6vh' }} />
        <Title titleText='お客様情報確認' />
        <div style={{ marginTop: '6vh' }} />
        <div style={{ textAlign: 'center' }}>
          Loading .....
        </div>
      </div>
    )
  } else {
    return (
      <div className={wrapper}>
        <InputProgressBar currentIndex={1} />
        <div style={{ marginTop: '18%' }} />
        <Title titleText='最後に入力した内容をご確認ください。' />
        <div style={{ marginTop: '8%' }} />
        <ul style={listContainer}> {filledFormList(carFormList)}</ul >
        <div style={{ marginTop: '8%' }} />
        <ul style={listContainer}> {filledFormList(profileFormList)}</ul >
        <div style={{ marginTop: '8%' }} />
        <GridView
          carImageDataAndDescription={showingImageData}
          setCarImageDataAndDescription={() => { }}
          allowEditing={false}
        />
        <div style={{ marginTop: '8%' }} />
        <SubmitButton buttonText='送信' onClick={() => onSubmit()} isEnabled={!isSending} />
        <div style={{ marginTop: '5%' }} />
      </div>
    );
  }
};

const listContainer = {
  width: '94vw',
  listStyle: 'none',
  padding: 0,
  marginLeft: '3vw',
};
const labelContainer = { fontSize: 10, width: '100%', };
const filledFormContainer = { fontSize: 14, width: '100%', borderBottom: '1px solid #EDEDED', padding: 2 };

export default Confirm;