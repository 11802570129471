import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
//customer
import Top from './Screens/Customer/Top';
import Phone from './Screens/Customer/Phone';
import PINcode from './Screens/Customer/PINcode';
import Input from './Screens/Customer/Input';
import Photos from './Screens/Customer/Photos';
import Confirm from './Screens/Customer/Confirm';
import Submit from './Screens/Customer/Submit';
//store
import LogIn from './Screens/Store/LogIn';
import LogOut from './Screens/Store/LogOut';
import Admin from './Screens/Store/Admin';
import CarDetail from './Screens/Store/CarDetail';
import StoreInfo from './Screens/Store/StoreInfo';
import EditStoreInfo from './Screens/Store/EditStoreInfo';
import Account from './Screens/Store/Account';
import CarRegistration from './Screens/Store/CarRegistration'

import NotFound from './Screens/NotFound';


class App extends Component {
  render() {
    return (
      <div style={container}>
        <Switch>
          <Route exact path='/' >
            <Redirect to="/user/g-complete" />
          </Route>
          <Route exact path='/user/:account_name' component={Top} />
          <Route exact path='/user/:account_name/phone' component={Phone} />
          <Route exact path='/user/:account_name/pincode/' component={PINcode} />
          <Route exact path='/user/:account_name/input/' component={Input} />
          <Route exact path='/user/:account_name/photos' component={Photos} />
          <Route exact path='/user/:account_name/confirm' component={Confirm} />
          <Route exact path='/user/:account_name/submit/' component={Submit} />

          <Route exact path='/admin/login' component={LogIn} />
          <Route exact path='/admin/logout' component={LogOut} />

          <PrivateRoute exact path='/admin/:account_name' component={Admin} />
          <PrivateRoute exact path='/admin/:account_name/store_info' component={StoreInfo} />
          <PrivateRoute exact path='/admin/:account_name/store_info/edit' component={EditStoreInfo} />
          <PrivateRoute exact path='/admin/:account_name/edit_account' component={Account} />
          <PrivateRoute exact path='/admin/:account_name/register_car' component={CarRegistration} />
          <PrivateRoute exact path='/admin/:account_name/:car_id' component={CarDetail} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

const container = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 0,
  height: '100%',
  overflow: 'scroll',
}

export default App;