import axios from 'axios';
import { AUTH_URL } from "../config.js";
//functions
import { cryptHash } from '../Functions';

class AuthService {
  isLoggedIn = () => this.get('isLoggedIn') === 'true';
  getCurrentUser = () => JSON.parse(this.get('user'));

  set = (key, value) => sessionStorage.setItem(key, value);
  get = key => this.getSessionStorage(key);

  getSessionStorage = key => {
    const ret = sessionStorage.getItem(key);
    if (ret) {
      return ret;
    }
    return null;
  };

  // ログイン処理
  login = async (accountName, password) => {
    const json = {
      account_name: accountName,
      password: cryptHash(password)
    };

    return await axios.post(AUTH_URL + '/signin', json)
      .then(result => {
        if (result.status === 200) {
          if (result.data[0]) {
            this.set('isLoggedIn', true);
            this.set('user', JSON.stringify(result.data[0]));

            return result.data[0].account_name;
          }
        }
      });
  };

  // ログアウト処理
  logout = () => {
    if (this.isLoggedIn()) {
      this.set('isLoggedIn', false);
      this.set('user', null);
    }
  };
}

export default new AuthService();
