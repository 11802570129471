import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
//components
import { Footer, Meta } from '../../Components/AdminComponents';
import { LoginStyle } from '../../Components/StyledComponents';
import Button from '../../Components/Button';
//services
import AuthService from '../../Services/AuthService';
//config
import { Colors, TextSize } from '../../config';

const LogIn = () => {
  const history = useHistory();
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const loginHandler = async () => {
    await AuthService.login(accountName, password)
      .then(result => {
        if (result) {
          history.push(`/admin/${result}`);
        } else {
          setError('アカウント名かパスワードが違います');
        }
      })
      .catch(err => {
        console.log(err);
        setError('ログインできませんでした')
      });
  }

  return (
    
    <div style={container}>
      <Meta />
      <LoginStyle>
        <div style={{ fontSize: TextSize.minimum, padding: '15%', marginTop: 30 }}>
          <div>
            スマホで査定
          </div>
          <div style={{ fontSize: TextSize.maximum, fontWeight: 'bolder' }}>
            サテコン
          </div>
          <div style={{ fontSize: TextSize.minimum, marginTop: 33 }}>
            Admin Console
          </div>
        </div>
        {error && (
          <Alert variant="danger">{error}</Alert>
        )}
        <div>
          <input type="accountName" name="accountName" placeholder='アカウント名' onBlur={(e) => setAccountName(e.target.value)} style={{ ...inputContainer, marginBottom: 15 }} />
        </div>
        <div>
          <input type="password" name="password" placeholder='パスワード' onBlur={(e) => setPassword(e.target.value)} style={inputContainer} />
        </div>
        <div style={{ textAlign: 'center', alignItems: 'center', margin: '10%', display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => loginHandler()}>
            ログイン
          </Button>
        </div>
      </LoginStyle>
      <Footer />
    </div >
  )
};

export const container = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.background,
};

const inputContainer = {
  fontSize: 14,
  padding: 10,
  width: 260,
  height: 30,
  backgroundColor: Colors.background,
  border: 'solid 1px #707070'
};

const btnContainer = {
  width: 200,
  height: 40,
  padding: 3,
  fontSize: 14,
  backgroundColor: '#3EA5F7',
  border: '1px solid',
  margin: 'auto',
  color: 'white',
};

export default LogIn;