import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Header, Footer } from '../../Components/AdminComponents';
import { Body, TitleContainer, BodyElement, Container, TableStyle, TableTitle } from '../../Components/StyledComponents';
import Button from '../../Components/Button';
import Modal from 'react-modal';
import axios from 'axios';
import Ajv from 'ajv';
import { EditAccountName, EditPassword } from './EditAccount';
//service
import AuthService from '../../Services/AuthService';
//api
import { FETCH_STORE_URL, TextSize, Colors } from '../../config';

const accountText = 'min(18px, max(14px, 1.1vw))';

const Account = () => {

  const [accountInfo, setAccountInfo] = useState({});
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isLoading) {
      const user = AuthService.getCurrentUser();
      const getStoreInfo = async () => {
        console.log(user.id);
        await axios.post(FETCH_STORE_URL, { id: user.id })
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            const pass = data[0].password;
            setPassword(pass);
            setAccountInfo(user);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          })
      }
      if (user.id) {
        getStoreInfo();
        console.log(user);
      }
    }
  }, [isLoading]);


  const setModalState = useCallback((isPassword) => {
    setIsPassword(isPassword);
    setIsModalOpen(true);
  }, [setIsModalOpen]);


  if (isLoading) {
    return (
      <Container>
        <Header />
        <Body>
          <TitleContainer style={{ justifyContent: 'flex-start', marginLeft: 10 }}>
            アカウント設定
          </TitleContainer>
          <BodyElement >

          </BodyElement>
        </Body>
        <Footer />
      </Container>
    )
  } else {
    return (
      <Container>
        <Header />
        <Body>
          <TitleContainer style={{ justifyContent: 'flex-start', marginLeft: 10 }}>
            アカウント設定
          </TitleContainer>
          <AccountSetting data={accountInfo} setModalState={(isPassword) => setModalState(isPassword)} />
          <Modal isOpen={isModalOpen} style={modalStyle}>
            <div className="field is-grouped is-grouped-right">
              <div className="control" style={{ paddingTop: 0 }}>
                {
                  isPassword ?
                    <EditPassword setIsModalOpen={setIsModalOpen} accountInfo={accountInfo} pass={password} setIsLoading={setIsLoading} />
                    :
                    <EditAccountName setIsModalOpen={setIsModalOpen} accountInfo={accountInfo} pass={password} />
                }
              </div>
            </div>
          </Modal>
        </Body>
        <Footer />
      </Container >
    )
  }
};

const AccountSetting = (props) => {

  const { data, setModalState } = props;

  return (
    <BodyElement style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', height: 210, backgroundColor: '#fAfAfA', padding: 20 }}>
      <div style={{ width: '70%' }}>
        <div>
          <label style={headerTextStyle}>
            ユーザー情報
          </label>
        </div>
      </div>
      <div style={{ width: '50%', marginTop: 20, border: '1px solid rgba(0, 0, 0, 0.1)' }}>
        <TableRow title={'アカウント名'} content={data.account_name} isFirstRow={true} onClick={() => setModalState(false)} />
        <TableRow title={'パスワード'} content={'＊＊＊＊＊＊'} isFirstRow={false} onClick={() => setModalState(true)} />
      </div>
    </BodyElement>
  )
}

const TableRow = (props) => {
  const { title, content, onClick, isFirstRow } = props;
  const borderTop = isFirstRow ? null : '1px solid rgba(0, 0, 0, 0.1)';
  return (
    <>
      <TableStyle style={{ borderTop: borderTop }}>
        <TableTitle style={{ fontSize: accountText }}>
          {title}
        </TableTitle>
        <div style={contentStyle}>
          {content}
        </div>
        <div >
          <Button onClick={() => onClick()}>
            変更
          </Button>
        </div>
      </TableStyle>
    </>
  )
}

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1000,
  },
  content: {
    maxWidth: '600px',
    maxHeight: '300px',
    top: '50%',
    marginTop: '-200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

const headerTextStyle = { fontSize: TextSize.medium, fontWeight: 'bold' };
const contentStyle = { width: '50%', fontSize: accountText, padding: 5 };

export default Account;