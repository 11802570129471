import axios from 'axios';
import { API_URL } from "../config.js";

class SmsService {
  sendPINcode = async (PINcode, to_number) => {
    const json = {
      "message": `お客様の確認コードは ${PINcode} です。`,
      "to_number": '+81' + to_number.replace('-', ''),
      "sender": 'satecon',
    };

    return await axios.post(API_URL + '/notification', json);
  };
}

export default new SmsService();