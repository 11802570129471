import React from 'react';
import { TextSize } from '../config';

const Title = (props) => {
  const { titleText } = props;
  return (
    <div style={titleStyle}>
      {titleText}
    </div>
  )
};

const titleStyle = { fontSize: TextSize.small, fontWeight: '600', textAlign: 'center', color: 'black' }

export default Title;