import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import { BodyElement, TableStyle, TableTitle, EditTableContentInput } from '../../Components/StyledComponents';
import Button from '../../Components/Button';
import axios from 'axios';
import Ajv from 'ajv';
//api
import { CAR_INFO_UID_UPDATE_URL, STORE_UID_UPDATE_URL, STORE_PASSWORD_UPDATE_URL, TextSize } from '../../config';

//functions
import { cryptHash } from '../../Functions';

const EditTableRow = (props) => {
  const { title, setText, textType, isFirstRow } = props;
  const borderTop = isFirstRow ? null : '1px solid rgba(0, 0, 0, 0.1)';

  return (
    <>
      <TableStyle style={{ borderTop: borderTop }}>
        <TableTitle style={{ fontSize: TextSize.small }}>
          {title}
        </TableTitle>
        <EditTableContentInput style={{ fontSize: TextSize.small }} onChange={(e) => setText(e.target.value)} type={textType} />
      </TableStyle>
    </>
  )
}


export const EditAccountName = (props) => {
  const history = useHistory();
  const { setIsModalOpen, accountInfo, pass } = props;
  const [error, setError] = useState('');
  const [accountName, setAccountName] = useState('');
  const [password, setPassword] = useState('');

  const saveBtnPressed = useCallback(() => {

    const updateAccountName = async () => {
      await axios.post(STORE_UID_UPDATE_URL, { id: accountInfo.id, account_name: accountName })
        .then((response) => {
          if (response.status === 200) {
            return axios.post(CAR_INFO_UID_UPDATE_URL, { before_account_name: accountInfo.account_name, after_account_name: accountName })
          }
        })
        .then((response) => {
          if (response.status === 200) {
            let info = accountInfo;
            info['account_name'] = accountName;
            sessionStorage.setItem('user', JSON.stringify(info))
            setIsModalOpen(false)
            history.push({
              pathname: `/admin/${accountName}/edit_account`,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data &&
            error.response.data.indexOf('ER_DUP_ENTRY') !== -1) {
            setError('このアカウント名は既に使用されています');
          } else {
            setError('更新に失敗しました。時間を置いて再度お試しください');
          }
        })
    }

    const error = checkForm();
    if (error.length) {
      setError(error[0]);
    } else {
      setError('');
      updateAccountName();
    }
  }, [accountName, password]);

  const checkForm = () => {
    const ajv = Ajv({ allErrors: true });
    const errorMessage = [];

    const data = {
      'アカウント名': accountName,
      'パスワード': cryptHash(password),
    }

    const schema = {
      required: ['アカウント名', 'パスワード'],
      type: 'object',
      properties: {
        'アカウント名': {
          type: 'string',
          minLength: 1,
        },
        'パスワード': {
          type: 'string',
          minLength: 1,
        },
      },
    };

    const validate = ajv.compile(schema);
    const valid = validate(data);

    if (!valid) {
      validate.errors.forEach(error => {
        if (error.keyword === 'minLength') {
          if (error.dataPath === "['アカウント名']") {
            errorMessage.push(`アカウント名を入力してください`);
          } else {
            errorMessage.push(`パスワードを入力してください`);
          }
        }
      })
    }
    if (cryptHash(password) !== pass) {
      errorMessage.push('パスワードが一致しませんでした。');
    }

    const regex = /[^A-Za-z0-9-_]/;
    if (regex.test(accountName)) {
      errorMessage.push("アカウント名に使用できない文字が含まれています。");
    }

    return errorMessage;
  }

  return (
    <BodyElement style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 210 }}>
      <div style={{ width: '80%', marginTop: 20 }}>
        <div style={{ borderTop: '1px solid rgba(0, 0, 0, 0.5)', borderBottom: '1px solid rgba(0, 0, 0, 0.5)' }}>
          <label style={headerTextStyle}>
            アカウント名変更
          </label>
        </div>
      </div>
      <div style={{ width: '80%', marginTop: 20, border: '1px solid rgba(0, 0, 0, 0.1)' }}>
        <EditTableRow title={'新しいアカウント名'} isFirstRow={true} setText={(e) => setAccountName(e)} textType={'username'} />
        <EditTableRow title={'パスワード'} isFirstRow={false} setText={(e) => setPassword(e)} textType={'password'} />
      </div>
      <label style={{ fontSize: 12, color: 'red', marginTop: 10 }}>
        {error && error}
      </label>
      <div style={{ width: '80%', marginTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <Button onClick={() => setIsModalOpen(false)} buttonStyle={'cancel'}>
          キャンセル
        </Button>
        <Button onClick={() => saveBtnPressed()}>
          保存
      </Button>
      </div>
    </BodyElement>
  )
}

export const EditPassword = (props) => {
  const { setIsModalOpen, accountInfo, pass, setIsLoading } = props;
  const [error, setError] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedNewPassword, setConfirmedNewPassword] = useState('');

  const saveBtnPressed = useCallback(() => {
    const updatePassword = async () => {
      await axios.post(STORE_PASSWORD_UPDATE_URL, { id: accountInfo.id, password: cryptHash(newPassword) })
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(true);
            setIsModalOpen(false)
          }
        })
        .catch((error) => {
          console.log(error);
          setError('更新に失敗しました。時間を置いて再度お試しください');
        })
    }

    const error = checkForm();
    if (error.length) {
      setError(error[0]);
    } else {
      setError('');
      updatePassword();
    }
  }, [currentPassword, newPassword, confirmedNewPassword]);

  const checkForm = () => {
    const ajv = Ajv({ allErrors: true });
    const errorMessage = [];

    const data = {
      currentPassword: cryptHash(currentPassword),
      newPassword: newPassword,
      confirmedNewPassword: confirmedNewPassword,
    }

    const schema = {
      required: ['currentPassword', 'newPassword', 'confirmedNewPassword'],
      type: 'object',
      properties: {
        'currentPassword': {
          type: 'string',
          minLength: 1,
        },
        'newPassword': {
          type: 'string',
          minLength: 4,
        },
        'confirmedNewPassword': {
          type: 'string',
          minLength: 4,
        },
      },
    };

    const validate = ajv.compile(schema);
    const valid = validate(data);

    if (!valid) {
      validate.errors.forEach(error => {
        if (error.keyword === 'minLength') {
          if (error.dataPath === ".currentPassword") {
            errorMessage.push('現在のパスワードを入力してください。');
          } else if (error.dataPath === ".newPassword") {
            errorMessage.push('５文字以上の新しいパスワードを入力してください。');
          } else {
            errorMessage.push(`確認用のパスワードを入力してください。`);
          }
        }
      })
    }
    if (newPassword !== confirmedNewPassword) {
      errorMessage.push('確認用パスワードが一致しませんでした。');
    }
    if (cryptHash(currentPassword) !== pass) {
      errorMessage.push('現在のパスワードが一致しませんでした。');
    }
    return errorMessage;
  }


  return (
    <BodyElement style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 230 }}>
      <div style={{ width: '80%', marginTop: 20 }}>
        <div style={{ borderTop: '1px solid rgba(0, 0, 0, 0.5)', borderBottom: '1px solid rgba(0, 0, 0, 0.5)' }}>
          <label style={headerTextStyle}>
            パスワード変更
          </label>
        </div>
      </div>
      <div style={{ width: '80%', marginTop: 20, border: '1px solid rgba(0, 0, 0, 0.1)' }}>
        <EditTableRow title={'現在のパスワード'} content={'＊＊＊＊＊＊'} isFirstRow={true} setText={(e) => setCurrentPassword(e)} textType={'password'} />
        <EditTableRow title={'新しいパスワード'} content={'＊＊＊＊＊＊'} isFirstRow={false} setText={(e) => setNewPassword(e)} textType={'password'} />
        <EditTableRow title={'新しいパスワード'} content={'＊＊＊＊＊＊'} isFirstRow={false} setText={(e) => setConfirmedNewPassword(e)} textType={'password'} />
      </div>
      <label style={{ fontSize: 12, color: 'red', marginTop: 10 }}>
        {error && error}
      </label>
      <div style={{ width: '80%', marginTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <Button onClick={() => setIsModalOpen(false)} buttonStyle={'cancel'}>
          キャンセル
        </Button>
        <Button onClick={() => saveBtnPressed()}>
          保存
      </Button>
      </div>
    </BodyElement>
  )
}



const headerTextStyle = { fontSize: 16, fontWeight: 'bold', marginLeft: 5 };