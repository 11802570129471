import React, { useEffect, useState, useCallback } from 'react';
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select';
// import { Button, } from '../../Components/StyledComponents';
import Button from '../../Components/Button';
import Ajv from 'ajv';
import styled from 'styled-components';
//api
import { errorMessages } from '../../config';
//functions
import { getMakerOptions, getCarTypeOptions, getAgeOptions, getMileageOptions, getStatusOptions } from '../../Functions';
import { phoneNumberNoneProblem, formatTel } from '../../Functions';

export const EditCarInformation = props => {
  const { data, changeEditState, onChange, setError, error, isEditing } = props;
  const car_id = data.id;
  const [maker, setMaker] = useState('');
  const [carType, setCarType] = useState('');
  const [age, setAge] = useState();
  const [mileage, setMileage] = useState('');
  const [status, setStatus] = useState();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [mail, setMail] = useState('');
  const [makerOptions, setMakerOptions] = useState([]);
  const [carTypeOptions, setCarTypeOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [mileageOptions, setMileageOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const ageOptions = getAgeOptions();
    setAgeOptions(ageOptions);

    const miliageOptions = getMileageOptions();
    setMileageOptions(miliageOptions);

    const statusOptions = getStatusOptions();
    setStatusOptions(statusOptions);

    const getMakers = async () => {
      const makerOptions = await getMakerOptions();
      setMakerOptions(makerOptions);
    }

    getMakers();
    setMaker(data.maker);
    setCarType(data.car_type);
    setAge(data.age);
    setMileage(data.mileage);
    setStatus(data.status);
    setName(data.name);
    setPhone(data.phone);
    setAddress(data.address);
    setMail(data.mail);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const getCarTypes = async () => {
      setCarTypeOptions([]);
      const carTypeOptions = await getCarTypeOptions(maker);
      setCarTypeOptions(carTypeOptions);
    };

    getCarTypes();
  }, [maker]);


  const onChangeMaker = (e) => {
    if (!e) {
      setMaker(null);
    } else {
      setMaker(e.value);
    }

    setCarType(null);
  };

  const onChangeCarType = (e) => {
    if (!e) {
      setCarType(null);
    } else {
      setCarType(e.value);
    }
  };

  const onChangeAge = (e) => {
    if (!e) {
      setAge(null);
    } else {
      setAge(e.value);
    }
  };

  const onChangeMileage = (e) => {
    if (!e) {
      setMileage(null);
    } else {
      setMileage(e.value);
    }
  };

  const onChangeStatus = (e) => {
    if (!e) {
      setStatus(null);
    } else {
      setStatus(e.value);
    }
  };

  const cancelEditing = () => {
    changeEditState();
  };

  const saveButtonPressed = useCallback(() => {
    const err = checkError();
    if (err.length) {
      console.log(err)
      setError(err[0]);
    } else {
      setError('');
      const updatingdData = {
        age: age,
        maker: maker,
        carType: carType,
        mileage: mileage,
        status: status,
        register_date: data.register_date,
        name: name,
        phone: formatTel(phone),
        address: address,
        mail: mail,
        car_id: car_id,
      };
      onChange(updatingdData)
    }
  }, [age, maker, carType, mileage, status, name, phone, address, mail, car_id]);

  const checkError = () => {
    const ajv = Ajv({ allErrors: true });
    const errorMessagesMap = new Map(errorMessages);
    const errorMessage = [];
    let isPhoneEntered = true;
    let formatedPhoneNumber = '';


    const data = {
      maker: maker ? maker : undefined,
      carType: carType ? carType : undefined,
      age: age ? age : undefined,
      mileage: mileage ? mileage : undefined,
      name: name ? name : undefined,
      phone: phone ? phone : undefined,
      address: address ? address : undefined,
      mail: mail,
    }

    const schema = {
      required: ['maker', 'carType', 'age', 'mileage', 'name', 'phone', 'address', 'mail'],
      type: 'object',
      properties: {
        mail: {
          type: 'string',
          format: 'email',
        },
      },
    };

    const validate = ajv.compile(schema);
    const valid = validate(data);

    if (!valid) {
      console.log(validate.errors);
      validate.errors.forEach(error => {
        if (error.keyword === 'required') {
          if (error.params.missingProperty === 'phone') {
            isPhoneEntered = false;
          }
          errorMessage.push(errorMessagesMap.get(error.params.missingProperty) + '\n');
        } else if (error.keyword === 'format') {
          errorMessage.push('メールアドレスのフォーマットが正しくありません。\n')
        }
      })
    }

    if (isPhoneEntered) {
      if (!phoneNumberNoneProblem(phone)) {
        errorMessage.push('電話番号の形式が正しくありません。\n');
      }

      formatedPhoneNumber = formatTel(phone);
      const isHaihun = RegExp('-');

      if (!isHaihun.test(formatedPhoneNumber)) {
        errorMessage.push('電話番号の形式が正しくありません。\n');
      }
    }

    return errorMessage;
  }

  if (isLoading) {
    return (
      <div />
    )
  } else {
    return (
      <div>
        <div style={{ width: '100%', height: '80%', overflowY: 'auto', paddingTop: 70 }}>
          {
            isEditing ?
              <div style={cntentStyle}>
                <label style={labelStyle}>ステータス</label>
                <Select value={statusOptions[status]}
                  onChange={(value) => onChangeStatus(value)} options={statusOptions} className='edit-info-selection'
                />
              </div>
              :
              <div />
          }
          <div style={{ height: 28 }} />

          <div style={cntentStyle}>
            <label style={labelStyle}>メーカー</label>
            <CreatableSelect value={maker ? [{ label: maker, value: maker }] : null} isClearable={true} isSearchable={true} options={makerOptions}
              onChange={(value) => onChangeMaker(value)} className={'edit-info-selection'} />
          </div>
          <div style={cntentStyle}>
            <label style={labelStyle}>車種</label>
            <CreatableSelect value={carType ? [{ label: carType, value: carType }] : null} isClearable={true} isSearchable={true} options={carTypeOptions}
              onChange={(value) => onChangeCarType(value)} className={'edit-info-selection'} />
          </div>
          <div style={cntentStyle}>
            <label style={labelStyle}>年式</label>
            <CreatableSelect value={age ? [{ label: age, value: age }] : null} isClearable={true} isSearchable={true} options={ageOptions}
              onChange={(value) => onChangeAge(value)} className={'edit-info-selection'} />
          </div>
          <div style={cntentStyle}>
            <label style={labelStyle}>走行距離</label>
            <CreatableSelect value={mileage ? [{ label: mileage, value: mileage }] : null} isClearable={true} isSearchable={true} options={mileageOptions}
              onChange={(value) => onChangeMileage(value)} className={'edit-info-selection'} />
          </div>
          <div style={{ height: 28 }} />

          <div style={cntentStyle}>
            <label style={labelStyle}>氏名</label>
            <input defaultValue={name} type="text" name="name" onBlur={(e) => setName(e.target.value)} style={inputContainer} />
          </div>
          <div style={cntentStyle}>
            <label style={labelStyle}>電話番号</label>
            <input defaultValue={phone} type="tel" name="phone" onBlur={(e) => setPhone(e.target.value)} style={inputContainer} />
          </div>
          <div style={cntentStyle}>
            <label style={labelStyle}>メールアドレス</label>
            <input defaultValue={mail} type="text" name="mail" onBlur={(e) => setMail(e.target.value)} style={inputContainer} />
          </div>
          <div style={cntentStyle}>
            <label style={labelStyle}>住所</label>
            <input defaultValue={address} type="text" name="address" onBlur={(e) => setAddress(e.target.value)} style={inputContainer} />
          </div>
        </div>
        {error !== '' ?
          <div style={{ width: '100%', height: '10%', textAlign: 'center', color: 'red', fontSize: 12, alignItems: 'center' }}>
            {error}
          </div>
          :
          <div style={{ height: 30 }} />
        }
        <ButtonContainer>
          <Button onClick={cancelEditing} buttonStyle={'cancel'}>キャンセル</Button>
          <Button onClick={saveButtonPressed}>保存</Button>
        </ButtonContainer>
      </div>
    )
  }
}

const ButtonContainer = styled.div`
  width: 100%;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media screen and (max-width: 620px) {
    justify-content: center;
  }

`
const cntentStyle = { display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'flex-start', alignItems: 'baseline', marginBottom: 5 };
const labelStyle = { fontSize: 10, color: '#9A9A9A', width: 88, };
const inputContainer = { fontSize: 12, width: 180, height: 25 };