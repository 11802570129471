import axios from 'axios';
import { DOMAIN_NAME, SERVICE_NAME, SENDER_ADDRESS, EMAIL_URL } from "../config.js";

class EmailService {
  SendTextCustomer = async (to, name, contactNumber) => {
    const from = SENDER_ADDRESS;
    const subject = `【${SERVICE_NAME}】査定依頼を受け付けました`;
    let bodyText = `${name}様\r\n`;
    bodyText = bodyText + '\r\n';
    bodyText = bodyText + `【${SERVICE_NAME}】で査定をお申し込みいただきありがとうございます。\r\n`;
    bodyText = bodyText + `お客様のお問い合わせ番号は[${contactNumber}]です。\r\n`;
    bodyText = bodyText + '詳細につきましては弊社担当より直接ご連絡致します。\r\n';
    bodyText = bodyText + '\r\n';
    bodyText = bodyText + '---------------------------\r\n';
    bodyText = bodyText + '\r\n';
    bodyText = bodyText + '本メールは自動返信されております。\r\n';
    bodyText = bodyText + 'このアドレスにご返信いただいても、内容確認及びご返答ができません。\r\n';

    const json = {
      "from": from,
      "to": to,
      "subject": subject,
      "bodyText": bodyText
    }

    return await axios.post(EMAIL_URL + '/sendtext', json);
  };

  SendTextStore = async (to) => {
    const from = SENDER_ADDRESS;
    const subject = `【${SERVICE_NAME}】査定依頼がありました`;
    let bodyText = '';
    bodyText = bodyText + `【${SERVICE_NAME}】で査定依頼がありました。\r\n`;
    bodyText = bodyText + '詳細につきましては管理画面にログインして確認してください。\r\n';
    bodyText = bodyText + '\r\n';
    bodyText = bodyText + `https://${DOMAIN_NAME}/admin/login\r\n`;
    bodyText = bodyText + '\r\n';
    bodyText = bodyText + '---------------------------\r\n';
    bodyText = bodyText + '\r\n';
    bodyText = bodyText + '本メールは自動送信されております。\r\n';

    const json = {
      "from": from,
      "to": to,
      "subject": subject,
      "bodyText": bodyText
    }

    return await axios.post(EMAIL_URL + '/sendtext', json);
  };

  SendHtml = async (to, name, account_name) => {
    const from = SENDER_ADDRESS;
    const subject = `【${SERVICE_NAME}】簡単査定のご案内`;
    const serviceName = SERVICE_NAME;
    const phone = '0287-47-5544';
    const mail = 'w.yagisawa.complete@gmail.com';
    const url = 'https://g-complete.co.jp/';
    const link = `https://${DOMAIN_NAME}/user/${account_name}`;
    const bodyText = '';
    const bodyHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"
  xmlns:v="urn:schemas-microsoft-com:vml">

<head>
  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
  <meta content="width=device-width" name="viewport" />
  <meta content="IE=edge" http-equiv="X-UA-Compatible" />
  <title></title>
  <style type="text/css">
    body {
      margin: 0;
      padding: 0;
    }

    table,
    td,
    tr {
      vertical-align: top;
      border-collapse: collapse;
    }

    * {
      line-height: inherit;
    }

    a[x-apple-data-detectors=true] {
      color: inherit !important;
      text-decoration: none !important;
    }
  </style>
  <style id="media-query" type="text/css">
    @media (max-width: 700px) {

      .block-grid,
      .col {
        min-width: 320px !important;
        max-width: 100% !important;
        display: block !important;
      }

      .block-grid {
        width: 100% !important;
      }

      .col {
        width: 100% !important;
      }

      .col_cont {
        margin: 0 auto;
      }

      img.fullwidth,
      img.fullwidthOnMobile {
        max-width: 100% !important;
      }

      .no-stack .col {
        min-width: 0 !important;
        display: table-cell !important;
      }

      .no-stack.two-up .col {
        width: 50% !important;
      }

      .no-stack .col.num2 {
        width: 16.6% !important;
      }

      .no-stack .col.num3 {
        width: 25% !important;
      }

      .no-stack .col.num4 {
        width: 33% !important;
      }

      .no-stack .col.num5 {
        width: 41.6% !important;
      }

      .no-stack .col.num6 {
        width: 50% !important;
      }

      .no-stack .col.num7 {
        width: 58.3% !important;
      }

      .no-stack .col.num8 {
        width: 66.6% !important;
      }

      .no-stack .col.num9 {
        width: 75% !important;
      }

      .no-stack .col.num10 {
        width: 83.3% !important;
      }

      .video-block {
        max-width: none !important;
      }

      .mobile_hide {
        min-height: 0px;
        max-height: 0px;
        max-width: 0px;
        display: none;
        overflow: hidden;
        font-size: 0px;
      }

      .desktop_hide {
        display: block !important;
        max-height: none !important;
      }
    }
  </style>
</head>

<body class="clean-body" style="margin: 0; padding: 0; -webkit-text-size-adjust: 100%; background-color: #ffffff;">
  <table bgcolor="#ffffff" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
    style="table-layout: fixed; vertical-align: top; min-width: 320px; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; width: 100%;"
    valign="top" width="100%">
    <tbody>
      <tr style="vertical-align: top;" valign="top">
        <td style="word-break: break-word; vertical-align: top;" valign="top">
          <div class="block-grid"
            style="min-width: 320px; max-width: 680px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
            <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
              <div class="col num12"
                style="min-width: 320px; max-width: 680px; display: table-cell; vertical-align: top; width: 680px;">
                <div class="col_cont" style="width:100% !important;">
                  <div
                    style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:30px; padding-bottom:25px; padding-right: 0px; padding-left: 0px;">
                    <div align="center" class="img-container center fixedwidth"
                      style="padding-right: 10px;padding-left: 10px;">
                      <div style="font-size:1px;line-height:10px"> </div> <img align="center" alt="Your Logo" border="0"
                        class="center fixedwidth" src="https://systemdesignworks.co.jp/assets/imgs/develop/sample.jpg"
                        style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 102px; display: block;"
                        title="Your Logo" width="102" />
                      <div style="font-size:1px;line-height:10px"> </div>
                    </div>
                    <div
                      style="color:#ffffff;font-family:'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                      <div
                        style="line-height: 1.2; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 12px; color: #131313; mso-line-height-alt: 14px;">
                        <p
                          style="font-size: 42px; line-height: 1.2; word-break: break-word; text-align: center; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 50px; margin: 0;">
                          <span style="font-size: 42px;">${serviceName}</span></p>
                        <p
                          style="font-size: 42px; line-height: 1.2; word-break: break-word; text-align: center; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 50px; margin: 0;">
                          <span style="font-size: 42px;">簡単査定のご案内</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color:#ffffff;">
            <div class="block-grid"
              style="min-width: 320px; max-width: 680px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
              <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                <div class="col num12"
                  style="min-width: 320px; max-width: 680px; display: table-cell; vertical-align: top; width: 680px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:30px; padding-bottom:50px; padding-right: 0px; padding-left: 0px;">
                      <div
                        style="color:#131313;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; color: #131313; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 20px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 24px; margin: 0;">
                            <span style="font-size: 20px;">${name}様</span></p>
                        </div>
                      </div>
                      <div
                        style="color:#131313;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.5;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.5; font-size: 12px; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; color: #131313; mso-line-height-alt: 18px;">
                          <p
                            style="line-height: 1.5; word-break: break-word; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; mso-line-height-alt: 21px; margin: 0;">
                            <span
                              style="font-size: 14px;">この度は、お車の査定に興味を持っていただき誠にありがとうございます。当社では、ただお客様に写真を撮っていただくだけで、会わなくてもお車の査定が出来る、そして契約までも出来てしまう、そんなシステムを取り入れております。「一括査定サイトに登録すると毎日しつこい電話が沢山かかってくる。」、「査定をお願いしたら2時間居座られた。」そんなストレス一切なしの超簡単でスマートな査定システムになります！！スマホさえあれば誰でもすぐに査定が可能です！！ぜひ使ってみてください！！</span>
                          </p>
                        </div>
                      </div>
                      <div align="center" class="button-container"
                        style="padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;"> <a href="${link}"
                          style="-webkit-text-size-adjust: none; text-decoration: none; display: inline-block; color: #5c4c99; background-color: #f7ce3e; border-radius: 25px; -webkit-border-radius: 25px; -moz-border-radius: 25px; width: auto; width: auto; border-top: 3px solid #F7CE3E; border-right: 3px solid #F7CE3E; border-bottom: 3px solid #F7CE3E; border-left: 3px solid #F7CE3E; padding-top: 5px; padding-bottom: 5px; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; text-align: center; mso-border-alt: none; word-break: keep-all;"
                          target="_blank"><span
                            style="padding-left:20px;padding-right:20px;font-size:18px;display:inline-block;"><span
                              style="font-size: 16px; margin: 0; line-height: 2; word-break: break-word; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 32px;"><span
                                data-mce-style="font-size: 18px; line-height: 36px;"
                                style="font-size: 18px; line-height: 36px;">簡単3STEP　査定をする</span></span></span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color:#72d2dc;">
            <div class="block-grid"
              style="min-width: 320px; max-width: 680px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
              <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                <div class="col num12"
                  style="min-width: 320px; max-width: 680px; display: table-cell; vertical-align: top; width: 680px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:10px; padding-bottom:10px; padding-right: 10px; padding-left: 10px;">
                      <div
                        style="color:#f7ce3e;font-family:'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 12px; color: #f7ce3e; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 42px; line-height: 1.2; word-break: break-word; text-align: center; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 50px; margin: 0;">
                            <span style="color: #5c4c99; font-size: 42px;">お手持ちのスマートフォンから</span></p>
                          <p
                            style="font-size: 42px; line-height: 1.2; word-break: break-word; text-align: center; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 50px; margin: 0;">
                            <span style="color: #5c4c99; font-size: 42px;">簡単3STEPで査定ができます！</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color:transparent;">
            <div class="block-grid"
              style="min-width: 320px; max-width: 680px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
              <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                <div class="col num12"
                  style="min-width: 320px; max-width: 680px; display: table-cell; vertical-align: top; width: 680px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                      <table border="0" cellpadding="0" cellspacing="0" class="divider" role="presentation"
                        style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"
                        valign="top" width="100%">
                        <tbody>
                          <tr style="vertical-align: top;" valign="top">
                            <td class="divider_inner"
                              style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;"
                              valign="top">
                              <table align="center" border="0" cellpadding="0" cellspacing="0" class="divider_content"
                                role="presentation"
                                style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 1px solid #BBBBBB; width: 100%;"
                                valign="top" width="100%">
                                <tbody>
                                  <tr style="vertical-align: top;" valign="top">
                                    <td
                                      style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"
                                      valign="top"><span></span></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color:transparent;">
            <div class="block-grid three-up"
              style="min-width: 320px; max-width: 680px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
              <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                <div class="col num4"
                  style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 224px; width: 226px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                      <div align="center" class="img-container center fixedwidth"
                        style="padding-right: 10px;padding-left: 10px;">
                        <div style="font-size:1px;line-height:10px"> </div><img align="center" alt="Your Logo"
                          border="0" class="center fixedwidth"
                          src="https://systemdesignworks.co.jp/assets/imgs/develop/sample.jpg"
                          style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 102px; display: block;"
                          title="Your Logo" width="102" />
                        <div style="font-size:1px;line-height:10px"> </div>
                      </div>
                      <div
                        style="color:#555555;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 30px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 36px; margin: 0;">
                            <span style="font-size: 30px;">STEP1</span></p>
                        </div>
                      </div>
                      <div
                        style="color:#555555;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">
                            本メールの査定するボタンをクリック！</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col num4"
                  style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 224px; width: 226px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                      <div align="center" class="img-container center fixedwidth"
                        style="padding-right: 10px;padding-left: 10px;">
                        <div style="font-size:1px;line-height:10px"> </div><img align="center" alt="Your Logo"
                          border="0" class="center fixedwidth"
                          src="https://systemdesignworks.co.jp/assets/imgs/develop/sample.jpg"
                          style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 102px; display: block;"
                          title="Your Logo" width="102" />
                        <div style="font-size:1px;line-height:10px"> </div>
                      </div>
                      <div
                        style="color:#555555;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 30px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 36px; margin: 0;">
                            <span style="font-size: 30px;">STEP2</span></p>
                        </div>
                      </div>
                      <div
                        style="color:#555555;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">
                            査定したいお車の情報、写真を撮影し送信！</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col num4"
                  style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 224px; width: 226px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                      <div align="center" class="img-container center fixedwidth"
                        style="padding-right: 10px;padding-left: 10px;">
                        <div style="font-size:1px;line-height:10px"> </div><img align="center" alt="Your Logo"
                          border="0" class="center fixedwidth"
                          src="https://systemdesignworks.co.jp/assets/imgs/develop/sample.jpg"
                          style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 102px; display: block;"
                          title="Your Logo" width="102" />
                        <div style="font-size:1px;line-height:10px"> </div>
                      </div>
                      <div
                        style="color:#555555;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 30px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 36px; margin: 0;">
                            <span style="font-size: 30px;">STEP3</span></p>
                        </div>
                      </div>
                      <div
                        style="color:#555555;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">
                            後日、査定金額を</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color:transparent;">
            <div class="block-grid"
              style="min-width: 320px; max-width: 680px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
              <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                <div class="col num12"
                  style="min-width: 320px; max-width: 680px; display: table-cell; vertical-align: top; width: 680px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                      <div align="center" class="button-container"
                        style="padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;"> <a href="${link}"
                          style="-webkit-text-size-adjust: none; text-decoration: none; display: inline-block; color: #5c4c99; background-color: #f7ce3e; border-radius: 25px; -webkit-border-radius: 25px; -moz-border-radius: 25px; width: auto; width: auto; border-top: 3px solid #F7CE3E; border-right: 3px solid #F7CE3E; border-bottom: 3px solid #F7CE3E; border-left: 3px solid #F7CE3E; padding-top: 5px; padding-bottom: 5px; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; text-align: center; mso-border-alt: none; word-break: keep-all;"
                          target="_blank"><span
                            style="padding-left:20px;padding-right:20px;font-size:18px;display:inline-block;"><span
                              style="font-size: 16px; margin: 0; line-height: 2; word-break: break-word; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; mso-line-height-alt: 32px;"><span
                                data-mce-style="font-size: 18px; line-height: 36px;"
                                style="font-size: 18px; line-height: 36px;">簡単3STEP　査定をする</span></span></span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color:transparent;">
            <div class="block-grid"
              style="min-width: 320px; max-width: 680px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
              <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                <div class="col num12"
                  style="min-width: 320px; max-width: 680px; display: table-cell; vertical-align: top; width: 680px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                      <table border="0" cellpadding="0" cellspacing="0" class="divider" role="presentation"
                        style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"
                        valign="top" width="100%">
                        <tbody>
                          <tr style="vertical-align: top;" valign="top">
                            <td class="divider_inner"
                              style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;"
                              valign="top">
                              <table align="center" border="0" cellpadding="0" cellspacing="0" class="divider_content"
                                role="presentation"
                                style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 1px solid #BBBBBB; width: 100%;"
                                valign="top" width="100%">
                                <tbody>
                                  <tr style="vertical-align: top;" valign="top">
                                    <td
                                      style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"
                                      valign="top"><span></span></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="background-color:#373737;">
            <div class="block-grid three-up"
              style="min-width: 320px; max-width: 680px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
              <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                <div class="col num4"
                  style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 224px; width: 226px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:30px; padding-bottom:30px; padding-right: 0px; padding-left: 0px;">
                      <div align="center" class="img-container center fixedwidth"
                        style="padding-right: 10px;padding-left: 10px;">
                        <div style="font-size:1px;line-height:10px"> </div><img align="center" alt="Your Logo"
                          border="0" class="center fixedwidth"
                          src="https://systemdesignworks.co.jp/assets/imgs/develop/sample.jpg"
                          style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 102px; display: block;"
                          title="Your Logo" width="102" />
                        <div style="font-size:1px;line-height:10px"> </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col num4"
                  style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 224px; width: 226px;">
                  <div class="col_cont" style="width:100% !important;">
                    <div
                      style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:30px; padding-bottom:30px; padding-right: 0px; padding-left: 0px;">
                      <div
                        style="color:#ffffff;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; color: #ffffff; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 18px; line-height: 1.2; text-align: left; word-break: break-word; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 22px; margin: 0;">
                            <span style="color: #ffffff;"><strong><span style="">会社概要</span></strong>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div
                        style="color:#ffffff;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; color: #ffffff; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 14px; line-height: 1.2; text-align: left; word-break: break-word; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 17px; margin: 0;">
                            <span style="color: #ffffff;">${mail}</span></p>
                          <p
                            style="font-size: 14px; line-height: 1.2; text-align: left; word-break: break-word; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 17px; margin: 0;">
                            <span style="color: #ffffff;">${url}</span></p>
                          <p
                            style="font-size: 14px; line-height: 1.2; text-align: left; word-break: break-word; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 17px; margin: 0;">
                            ${phone}</p>
                        </div>
                      </div>
                      <div
                        style="color:#ffffff;font-family:Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div
                          style="line-height: 1.2; font-size: 12px; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; color: #ffffff; mso-line-height-alt: 14px;">
                          <p
                            style="font-size: 12px; line-height: 1.2; text-align: left; word-break: break-word; font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif; mso-line-height-alt: 14px; margin: 0;">
                            <span style="color: #fff1eb; font-size: 12px;"><span
                                style="color: #ffffff;">配信停止は</span><span style="color: #f7ce3e;"><a href="#"
                                  rel="noopener" style="text-decoration: underline; color: #f7ce3e;"
                                  target="_blank">こちら</a></span></span><span style="font-size: 12px;"><span
                                style="">から</span></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </td>
      </tr>
    </tbody>
  </table>
</body>

</html>`;

    const json = {
      "from": from,
      "to": to,
      "subject": subject,
      "bodyHtml": bodyHtml,
      "bodyText": bodyText
    }

    return await axios.post(EMAIL_URL + '/sendhtml', json);
  };
}

export default new EmailService();