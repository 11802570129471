import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Header, Footer } from '../../Components/AdminComponents';
import { Body, TitleContainer, Container, BodyElement } from '../../Components/StyledComponents';
import Button from '../../Components/Button';
import { Colors, TextSize } from '../../config';
//service
import ImageService from "../../Services/ImageService";
import AuthService from '../../Services/AuthService';

const StoreInfo = () => {
  const [storeData, setStoreData] = useState({});
  const [logoURL, setLogoURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    const storeLogo = user['logo_img'];
    if (storeLogo !== null) {
      const img = [{ img_name: storeLogo }];
      ImageService.getImageURL(img)
        .then((url) => {
          setLogoURL(url[0].img_name);
        })
        .catch((err) => {
          console.log(err);
        })
    }
    setStoreData({ ...user }, setIsLoading(false));
  }, []);

  const editStore = useCallback(() => {
    if (storeData.account_name !== undefined) {
      history.push({ pathname: `/admin/${storeData.account_name}/store_info/edit` });
    } else {
      history.push({ pathname: '/admin/login' });
    }
  }, [storeData]);

  if (isLoading) {
    return (
      <Container>
        <Header />
        <Body>
          <TitleContainer style={{ justifyContent: 'flex-start', marginLeft: 10 }}>
            店舗情報
          </TitleContainer>
          <BodyElement style={{ backgroundColor: Colors.bodyBackgroundColor }}>

          </BodyElement>
        </Body>
        <Footer />
      </Container>
    )
  } else {
    return (
      <Container>
        <Header />
        <Body>
          <TitleContainer style={{ justifyContent: 'flex-start', marginLeft: 10 }}>
            店舗情報
          </TitleContainer>
          <BodyElement style={{ backgroundColor: Colors.bodyBackgroundColor }}>
            <div style={bodyStyle}>
              <div style={{ margin: '10px 0' }}>
                <Button onClick={() => editStore()}> 編集</Button>
              </div>
              <div style={cntentStyle}>
                <div style={labelStyle}>
                  店舗名
                </div>
                <div style={{ fontSize: TextSize.small, overflowX: 'auto' }}>
                  {storeData.name}
                </div>
              </div>
              <div style={cntentStyle}>
                <div style={labelStyle}>
                  電話番号
                </div>
                <div style={{ fontSize: TextSize.small, overflowX: 'auto' }}>
                  {storeData.phone}
                </div>
              </div>
              <div style={cntentStyle}>
                <div style={labelStyle}>
                  住所
                </div>
                <div style={{ fontSize: TextSize.small, overflowX: 'auto' }}>
                  {storeData.address}
                </div>
              </div>
              <div style={cntentStyle}>
                <div style={labelStyle}>
                  メールアドレス
                </div>
                <div style={{ fontSize: TextSize.small, overflowX: 'auto' }}>
                  {storeData.mail}
                </div>
              </div>
              <div style={cntentStyle}>
                <div style={labelStyle}>
                  店舗ロゴ
                </div>
                <div style={{ width: 150, height: 150, backgroundColor: 'gray', alignSelf: 'center', marginBottom: 20 }} >
                  <img src={logoURL} alt={'Logo'} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                </div>
              </div>
            </div>
          </BodyElement>
        </Body>
        <Footer />
      </Container>
    )
  }
};

const bodyStyle = {
  width: '100%',
  padding: '1em'
}

export const labelStyle = { fontSize: TextSize.small, width: 120, color: Colors.labelColor };

export const cntentStyle = {
  height: 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 3,
  padding: 5,
  justifyContent: 'flex-start',
  alignItems: 'baseline',
};

export default StoreInfo;