import React, { useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Title from '../../Components/Title';
//components
import SubmitButton from '../../Components/SubmitButton';
import InputProgressBar from '../../Components/InputProgressBar';

const Submit = (props) => {
  const history = useHistory();
  const { state } = useLocation();
  const { contactNumber } = state;
  const { account_name } = useParams();

  const onClick = useCallback(() => {
    history.push({
      pathname: `/user/${account_name}`,
    });
  }, []);

  return (
    <div>
      <InputProgressBar currentIndex={2} />
      <div style={{ marginTop: '16%' }} />
      <Title titleText='入力お疲れ様でした！' />
      <Title titleText='担当者より査定結果をお知らせいたします。' />
      <div style={{ marginTop: '16%' }} />
      <div style={imageContainer}>
        <img src={require('../../assets/completeImage.png')} style={{ width: '100%', height: '100' }} />
      </div>
    </div>
  );
};

const CompletionMessage = ({ contactNumber }) => {
  return (
    <div style={{ textAlign: 'center', fontSize: 13 }}>
      <div>
        送信が完了しました。
      </div>
      <div>
        {`お問い合わせ番号は[${contactNumber}]です。`}
      </div>
      <div style={{ marginTop: '2vh' }} />
      <div>
        お客様には自動返信メールが送信されております。
      </div>
      <div>
        詳細につきましては弊社担当より直接ご連絡致します。
      </div>
    </div>
  );
};

const imageContainer = {
  width: '75%',
  maxWidth: 350,
  marginLeft: 'auto',
  marginRight: 'auto'
}

export default Submit;