import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { CSVLink } from "react-csv";
//api
import { DATA_LIST_URL, exampleCSVdata, Colors } from '../../config';
//components
import { FiDownload } from 'react-icons/fi';
import AddIcon from '@material-ui/icons/Add';
import { Header, Footer } from '../../Components/AdminComponents';
import SearchBar from '../../Components/SearchBar';
import { Container, Body, TitleContainer, MasterContainer, MasterItem, } from '../../Components/StyledComponents';
import Button from '../../Components/Button';
import CarList from '../../Components/CarList';
import FileImport from '../../Components/FileImport';
//services
import ImageService from '../../Services/ImageService';

const Admin = () => {
  const [searchedData, setSearchedData] = useState([]);
  const [carData, setCarData] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { account_name } = useParams();

  const dataLabel = { car_type: '車名', maker: 'メーカー', mileage: '走行距離', age: '年式' };

  useEffect(() => {
    const getResult = async () => {
      await axios.get(DATA_LIST_URL + `?account_name=${account_name}`)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          }
        })
        .then((result) => {
          const snapShot = result;
          ImageService.getImageURL(snapShot)
            .then((cars) => {
              console.log(cars)
              setCarData(cars);
              setSearchedData(cars);
              setIsLoading(false);
            })
        })
        .catch((error) => {
          console.log('error', error)
          setError(error);
        });
    }
    getResult();
  }, []);

  const setSearchingData = useCallback((carList) => {
    setSearchedData(carList)
  }, []);


  const updateCarData = useCallback((car_id, status) => {
    if (!isLoading) {
      const updateIndex = carData.findIndex((car) => { return car.id === car_id });
      let data = carData;
      data[updateIndex]['status'] = status;
      setCarData([...data]);
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <Container>
        <Header />
        <Body>
          Loading .....
        </Body>
      </Container>
    );
  } else {
    return (
      <Container>
        <Header />
        <Body>
          <TitleContainer>
            <div>
              査定依頼一覧
            </div>
          </TitleContainer>
          <MasterContainer>
            <SearchBar setSearchingData={(carList) => setSearchingData(carList)} carData={carData} />
            <MasterItem>
              <DownLoadCSV />
              <FileImport isButton={true} />
              <Link
                to={{
                  pathname: `/admin/${account_name}/register_car`,
                }}
                style={{ textDecoration: 'none', color: '#ffffff' }}
              >
                <Button>
                  <AddIcon style={{ fontSize: 14, marginRight: 4 }} />
                  新規登録
                </Button>
              </Link>
            </MasterItem>
          </MasterContainer>
          <CarList listData={searchedData} dataLabel={dataLabel} updateCarData={(car_id, status) => updateCarData(car_id, status)} />
        </Body>
        <Footer />
      </Container>
    );
  }
};

const DownLoadCSV = () => {

  const style = {
    minWidth: 75,
    minHeight: 35,
    padding: '0.3em',
    fontSize: 10,
    border: 'none',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'black',
    fontSize: 12,
    marginRight: 16,
  }

  return (
    <CSVLink
      data={exampleCSVdata}
      separator={","}
      filename={"登録用CSVファイル.csv"}
      target="_blank"
      style={style}
    >
      <FiDownload style={{ fontSize: 14, marginRight: 4 }} color='black' />
      登録用CSV
    </CSVLink>
  )
}

export default Admin;