export const carFormList = {
  maker: 'メーカー',
  carType: '車種',
  age: '年式',
  mileage: '走行距離',
};

export const profileFormList = {
  name: 'お名前',
  phone: '電話番号',
  address: '住所',
  mail: 'メールアドレス'
};

export const carImageData = [
  { partName: '斜め前方から', image: null, description: '', imageFile: null },
  { partName: '斜め後方から', image: null, description: '', imageFile: null },
  { partName: 'メーター', image: null, description: '', imageFile: null },
  { partName: '車検証', image: null, description: '', imageFile: null },
  { partName: '部位5', image: null, description: '', imageFile: null },
  { partName: '部位6', image: null, description: '', imageFile: null },
  { partName: '部位7', image: null, description: '', imageFile: null },
  { partName: '部位8', image: null, description: '', imageFile: null },
  { partName: '部位9', image: null, description: '', imageFile: null },
  { partName: '部位10', image: null, description: '', imageFile: null },
  { partName: '部位11', image: null, description: '', imageFile: null },
  { partName: '部位12', image: null, description: '', imageFile: null },
  { partName: '部位13', image: null, description: '', imageFile: null },
  { partName: '部位14', image: null, description: '', imageFile: null },
  { partName: '部位15', image: null, description: '', imageFile: null },
  { partName: '部位16', image: null, description: '', imageFile: null },
  { partName: '部位17', image: null, description: '', imageFile: null },
  { partName: '部位18', image: null, description: '', imageFile: null },
  { partName: '部位19', image: null, description: '', imageFile: null },
  { partName: '部位20', image: null, description: '', imageFile: null },
  { partName: '部位21', image: null, description: '', imageFile: null },
  { partName: '部位22', image: null, description: '', imageFile: null },
  { partName: '部位23', image: null, description: '', imageFile: null },
  { partName: '部位24', image: null, description: '', imageFile: null },
  { partName: '部位25', image: null, description: '', imageFile: null },
]

export const defaultCarImage = [
  require('../assets/1.png'),
  require('../assets/2.png'),
  require('../assets/default.png'),
  require('../assets/default.png'),
  require('../assets/5.png'),
  require('../assets/6.png'),
  require('../assets/7.png'),
  require('../assets/8.png'),
  require('../assets/9.png'),
  require('../assets/10.png'),
  require('../assets/11.png'),
  require('../assets/12.png'),
  require('../assets/13.png'),
  require('../assets/14.png'),
  require('../assets/15.png'),
  require('../assets/16.png'),
  require('../assets/17.png'),
  require('../assets/18.png'),
  require('../assets/19.png'),
  require('../assets/default.png'),
  require('../assets/default.png'),
  require('../assets/default.png'),
  require('../assets/default.png'),
  require('../assets/default.png'),
  require('../assets/default.png'),
]


export const requiredImageIndex = [0, 1, 2, 3];