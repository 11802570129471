import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Encoding from 'encoding-japanese';
import iconv from 'iconv-lite';
import csv from 'csvtojson';
import Ajv from 'ajv';
import FolderIcon from '@material-ui/icons/Folder';
import { DropZone, } from './StyledComponents';
import { ButtonStyle } from './Button';
import { INSERT_URL } from '../config';
import { phoneNumberNoneProblem, formatTel, Now } from '../Functions';

const FileImport = (props) => {
  const { isButton } = props;
  const { account_name } = useParams();

  const checkError = (item) => {
    const ajv = Ajv({ allErrors: true });

    const data = {
      maker: item.maker ? item.maker : undefined,
      carType: item.carType ? item.carType : undefined,
      age: item.age ? item.age : undefined,
      mileage: item.mileage ? item.mileage : undefined,
      name: item.name ? item.name : undefined,
      phone: item.phone ? item.phone : undefined,
      address: item.address ? item.address : undefined,
      mail: item.mail,
    }

    const schema = {
      required: ['maker', 'carType', 'age', 'mileage', 'name', 'phone', 'address', 'mail'],
      type: 'object',
      properties: {
        mail: {
          type: 'string',
          format: 'email',
        },
      },
    };

    const validate = ajv.compile(schema);
    const valid = validate(data);
    if (!valid) {
      return false;
    }

    if (!phoneNumberNoneProblem(item.phone)) {
      return false;
    }

    const formatedPhoneNumber = formatTel(item.phone);
    const isHaihun = RegExp('-');

    if (!isHaihun.test(formatedPhoneNumber)) {
      return false;
    }

    return true;
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        let importCount = 0;
        const importData = [];
        const buf = reader.result;
        const encoding = Encoding.detect(buf);
        const csvStr = Encoding.convert(buf, {
          to: 'unicode',
          from: encoding,
          type: 'string',
        })
        await csv({ noheader: false, output: "csv" }).fromString(csvStr)
          .then((csvRow) => {
            return csvRow;
          })
          .then((result) => {
            result.forEach(item =>
              importData.push({
                name: item['0'], phone: item['1'], mail: item['2'], address: item['3'], maker: item['4'], carType: item['5'], age: item['6'], mileage: item['7']
              })
            );
          })
          .catch((error) => {
            console.log(error);
          });

        for (const item of importData) {
          if (!checkError(item)) continue;

          await axios.post(INSERT_URL + "/user_info",
            {
              name: item.name,
              phone: formatTel(item.phone),
              address: item.address,
              mail: item.mail
            })
            .then(result => {
              console.log(result);
              const date = Now();
              return axios.post(INSERT_URL + "/car_info",
                {
                  maker: item.maker,
                  car_type: item.carType,
                  age: item.age,
                  mileage: item.mileage,
                  user_id: result.data.insertId,
                  account_name: account_name,
                  status: 0,
                  create_date: date,
                  update_date: date,
                  register_date: null
                });
            })
            .then(result => {
              console.log(result);
              importCount++;
            })
            .catch(error => {
              console.log('error', error);
            });
        }

        alert(`${importCount}件のデータを取り込みました`);

        if (importCount > 0)
          window.location.reload();
      };

      reader.readAsBinaryString(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'text/csv', onDrop, maxFiles: 1 });

  if (isButton) {
    return (
      <div>
        <input {...getInputProps()} />
        <ButtonStyle {...getRootProps()}>
          <FolderIcon style={{ fontSize: 16, marginRight: 4 }} />
          CSV登録
        </ButtonStyle>
      </div >
    )
  } else {
    return (
      <DropZone {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {
          <div style={{ fontSize: 16, textAlign: 'center' }}>
            <p>ここにファイルをドラッグ&ドロップ、または、クリックしてファイルを選択</p>
          </div>
        }
      </DropZone>
    );
  }
}

export default FileImport;