import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoMdSettings } from 'react-icons/io';
import { HeaderStyle, HeaderTitle, HeaderIcons, SideBarContent, FooterStyle, FooterColumn, FooterItems, DropDownStyle, DropDownItemStyle } from './StyledComponents';
import AuthService from '../Services/AuthService';
import { DOMAIN_NAME, TextSize } from '../config';
import { Helmet } from 'react-helmet';

export const Header = () => {
  const user = AuthService.getCurrentUser();
  const account_name = user ? user.account_name : null;
  if (!account_name) {
    return (
      <HeaderStyle>
        <Meta />
        <HeaderTitle>
        </HeaderTitle>
      </HeaderStyle>
    )
  } else {

    const options = [
      {
        text: '店舗情報',
        pathname: `/admin/${account_name}/store_info`,
      },
      {
        text: 'アカウント設定',
        pathname: `/admin/${account_name}/edit_account`,
      },
      {
        text: 'ログアウト',
        pathname: '/admin/logout',
      },
    ];

    return (
      <HeaderStyle>
        <Meta />
        <div style={{ height: 80, width: '100%', marginLeft: 33, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ fontSize: TextSize.small }}>
            スマホで査定
          </div>
          <div style={{ fontSize: TextSize.medium, fontWeight: 'bolder' }}>
            サテコン
          </div>
        </div>
        <div style={{ width: '100%', height: 40, backgroundColor: '#E8E8E8', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Link
            to={{
              pathname: `/admin/${account_name}`,
            }}
            style={{ color: 'gray', width: 'auto', height: 'auto' }}
          >
            <HeaderTitle>
              {`${user.name} 様`}
            </HeaderTitle>
          </Link>

          <HeaderIcons>
            <DropDown options={options} />
          </HeaderIcons>
        </div>
      </HeaderStyle>
    )
  }
};

const DropDown = (props) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <li style={{ listStyle: 'none', color: 'black' }}>
      <div style={{ cursor: 'pointer', alignItems: 'center' }} onClick={() => setIsOpened(!isOpened)}>
        <IoMdSettings style={{ width: TextSize.large, height: TextSize.large }} color='black' />
      </div>
      {isOpened && <DropDownMenue items={props.options} />}
    </li>
  )
}

const DropDownMenue = (props) => {

  const DropDownItem = (props) => {
    return (
      <Link
        to={{
          pathname: props.pathname
        }}
        style={{ color: 'gray', width: 'auto', height: '100%', marginTop: 20 }}
      >
        <DropDownItemStyle style={{ cursor: 'pointer' }}>
          <span>
            {props.leftIcon}
          </span>
          <div style={{ color: 'black' }}>
            {props.children}
          </div>
          <span>
            {props.rightIcon}
          </span>
        </DropDownItemStyle>
      </Link>
    )
  }

  return (
    <DropDownStyle>
      {
        props.items && props.items.map((item) => {
          return (
            <DropDownItem
              leftIcon={item.leftIcon && item.leftIcon}
              rightIcon={item.rightIcon && item.rightIcon}
              pathname={item.pathname}
              key={item.text}
            >
              {item.text}
            </DropDownItem>
          )
        })
      }
    </DropDownStyle>
  )
}


export const Body = () => {
  return (
    <div style={bodyStyle}>
    </div>
  )
};

export const Footer = () => {
  const user = AuthService.getCurrentUser();
  const account_name = user ? user.account_name : null;
  if (!account_name) {
    return (
      <FooterStyle style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <div style={{ fontSize: TextSize.minimum }}>
          {`Copyright © ${DOMAIN_NAME} All rights reserved.`}
        </div>
      </FooterStyle>
    )
  } else {

    return (
      <FooterStyle>
        <FooterColumn>
          <FooterItems >
            <div style={{ height: 180, width: '20%', minWidth: 200, backgroundColor: 'lightgray', textAlign: 'center' }}>
              LOGO
            </div>
            <div style={{ height: '100%', width: 200, display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={labelStyle}>
                  Garage Complete
                </label>
                <label style={labelStyle}>
                  ガレージコンプリート
                </label>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ ...smallLabelStyle, marginBottom: -5 }} >
                  〒329-2745栃木県那須塩原市三区町651-9
                </label>
                <label style={smallLabelStyle} >
                  TEL　0287-4705544　FAX　0287-47-5160
                </label>
                <label style={smallLabelStyle} >
                  営業時間平日9時0  0分〜19時0  0分
                </label>
                <label style={smallLabelStyle} >
                  定休日　土曜日・日曜日・祝日
                </label>
              </div>
            </div>
          </FooterItems>
          <div style={{
            height: 180, minWidth: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-around'
          }}>
            <div style={{ height: '100%', width: '30%', minWidth: 150, display: 'flex', flexDirection: 'column' }} >
              {/* <label style={smallLabelStyle}>
                プライバシーポリシー
              </label>
              <label style={smallLabelStyle}>
                特定商取引に基づく表記
              </label> */}
              <p style={{ fontSize: 12, fontWeight: 'normal', marginTop: 'auto' }}>
                {`Copyright © ${DOMAIN_NAME} All rights reserved.`}
              </p>
            </div>
          </div>
        </FooterColumn>
      </FooterStyle >
    )
  }
}

const labelStyle = { fontSize: 14, fontWeight: 'bolder' }
const smallLabelStyle = { fontSize: 12, fontWeight: 'normal' }

const bodyStyle = {
  position: 'absolute',
  right: 18,
  bottom: 18,
  width: 'calc(100vw - 18vw)',
  height: 'calc(100vh - 120px)',
  backgroundColor: 'gray',
  zIndex: 0,
}

export const Meta = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex"></meta>
    </Helmet>
  )
};