import React from 'react';
//components
import Title from '../Components/Title';

const NotFound = () => {
    return (
        <div style={container}>
            <Title titleText='Page Not Found' />
        </div >
    );
};

const container = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    height: '100vh',
    overflow: 'scroll',
};

export default NotFound;