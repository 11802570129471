import React, { useCallback, useState } from 'react';
import { defaultCarImage, requiredImageIndex } from '../Forms';
import { Colors, TextSize } from "../config";
import styled from 'styled-components';


const GridView = props => {
  //called from Photos

  const { carImageDataAndDescription, setCarImageDataAndDescription, allowEditing } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const swapImage = useCallback((img) => {
    if (img.target.files.length !== 0) {
      const imageFile = img.target.files[0]; // for sending to w3-wasabi
      const imageURL = URL.createObjectURL(img.target.files[0]); // for preview
      let carImageData = carImageDataAndDescription;
      carImageData[selectedIndex]['image'] = imageURL;
      carImageData[selectedIndex]['imageFile'] = imageFile;
      setCarImageDataAndDescription([...carImageData]);
    }
  }, [selectedIndex]);

  const changeText = useCallback((text) => {
    const enterdText = text.target.value;
    let carImageData = carImageDataAndDescription;
    carImageData[selectedIndex]['description'] = enterdText;
    setCarImageDataAndDescription([...carImageData]);
  });

  return (
    <div>
      <ul style={gridsContainer}>
        {
          carImageDataAndDescription && carImageDataAndDescription.slice(0, 4).map((item, ind) =>
            <Grid swapImage={swapImage} item={item} index={ind} setSelectedIndex={setSelectedIndex} changeText={changeText} allowEditing={allowEditing} />

          )
        }
      </ul>
      {
        allowEditing ?
          <div style={{
            position: 'relative', textAlign: 'center', margin: '10% 0 10% 0', fontSize: TextSize.small, fontWeight: 'bold'
          }}>
            <div>
              他にも写真を送信いただけると
          </div>
            <div>
              より正確な査定金額を出すことができます！
          </div>
          </div>
          :
          null
      }
      <ul style={gridsContainer}>
        {
          carImageDataAndDescription && carImageDataAndDescription.slice(4, carImageDataAndDescription.length).map((item, ind) => {
            const index = ind + 4;
            return (
              <Grid swapImage={swapImage} item={item} index={index} setSelectedIndex={setSelectedIndex} changeText={changeText} allowEditing={allowEditing} />

            )
          })
        }
      </ul>
    </div>
  );
};

const Grid = ({ swapImage, item, index, setSelectedIndex, changeText, allowEditing }) => {

  const textLimit = 100;
  const isRequired = requiredImageIndex.includes(index);

  return (
    <li style={gridContainer} key={index.toString()} >
      <div style={{ height: '7%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
        <div style={{ fontSize: 12, color: '#D9D9D9' }}>
          {item.partName}
        </div>
        {
          isRequired ?
            <div style={{ fontSize: 16, color: Colors.required, margin: '0 0 4px 4px' }}>
              ※
            </div>
            :
            null
        }
      </div>
      {
        allowEditing ?
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            hidden={true}
            onChange={(e) => swapImage(e)}
          />
          :
          null
      }
      <label htmlFor="contained-button-file" style={imageContainer} onClick={() => setSelectedIndex(index)}>
        {item.image !== null ?
          <img src={item.image} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
          :
          <div>
            <img src={defaultCarImage[index]} style={{ height: '97%', width: '97%', objectFit: 'cover', borderWidth: 'bold' }} />
          </div>
        }
      </label>
      {
        allowEditing ?
          <DescriptionTextArea
            type="text"
            placeholder={'備考'}
            value={item.description}
            onChange={item.description.length > textLimit ? console.log("limit reached") : changeText}
            onClick={() => setSelectedIndex(index)}
          />
          :
          <p style={descriotionField}>{item.description}</p>
      }
    </li >
  )
};

const gridsContainer = {
  overflow: 'scroll',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  padding: 6
}

const gridContainer = {
  width: 135,
  height: 240,
  backgroundColor: 'white',
  listStyle: 'none',
  margin: '1.2vw',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',

};

const imageContainer = {
  width: '100%',
  height: '50%',
  textAlign: 'center',
  border: '1px solid lightgray',
};

const DescriptionTextArea = styled.textarea`
  padding: 5px;
  height: 33%;
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  border: 1px solid lightgray;
  ::placeholder {
    color: lightgray
  }
`;

const descriotionField = {
  padding: 5,
  height: '33%',
  width: '100%',
  display: 'flex',
  margin: '0 auto',
  fontSize: 12,
  border: '1px solid lightgray'
}

export default GridView;

