import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactCodeInput from 'react-code-input';
import { Alert } from 'react-bootstrap';
//components
import Title from '../../Components/Title';
import SubTitle from '../../Components/SubTitle';
import { CustomerContainer } from '../../Components/StyledComponents'
//service
import SmsService from "../../Services/SmsService";
//config
import { TextSize } from '../../config';

const PINcode = () => {
  const history = useHistory();
  const rand = ("0000000" + Math.floor(Math.random() * 10000000)).slice(-6); // generate 6 digit conbined number
  const [PINcode, setPINcode] = useState(rand);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const { state } = useLocation();
  const { account_name } = useParams();
  console.log(PINcode);

  useEffect(() => {
    console.log(PINcode);
    if (state) {
      const { phone } = state;
      setPhone(phone);
    } else {
      history.push({
        pathname: `/user/${account_name}`,
      });
    }
  }, [state])

  useEffect(() => {
    console.log(PINcode);
    if (phone) {
      if (process.env.NODE_ENV === 'production') {
        // SMS通知
        SmsService.sendPINcode(PINcode, phone);
      }
    }
  }, [PINcode, phone]);

  const checkPIN = (e) => {
    console.log(e);
    if (e.length === 6) {
      if (e === PINcode) {
        const generalInfo = {
          phone: phone,
          isUpdate: false,
        };

        history.push({
          pathname: `/user/${account_name}/input`,
          state: { generalInfo: generalInfo }
        });
      } else {
        setError('確認コードが間違っています');
      }
    } else {
      setError('');
    }
  };

  const onClick = (e) => {
    e.preventDefault();
    const rand = ("0000000" + Math.floor(Math.random() * 10000000)).slice(-6); // generate 6 digit conbined number
    setPINcode(rand);
    setError('');
  };

  return (
    <CustomerContainer>
      <div style={{ marginTop: '20%' }} />
      {error && (
        <div style={{ whiteSpace: 'pre-line' }}>
          <Alert variant="danger">{error}</Alert>
        </div>
      )}
      <Title titleText='確認コードを入力してください。' />
      <div style={{ textAlign: 'center', margin: '10% 0 10% 0' }}>
        <ReactCodeInput type='tel' fields={6} inputStyle={{ width: '38px', height: '40px', fontSize: '20px', margin: '3px', textAlign: 'center' }} onChange={(e) => checkPIN(e)} />
      </div>
      <div style={{ marginTop: '1%' }} />
      {
        process.env.NODE_ENV !== 'production'
          ? <SubTitle subTitleText={`※開発者用確認コード : ${PINcode}`} />
          : null
      }
      <div style={{ textAlign: 'center' }}>
        <a href="#" onClick={onClick} isEnabled={true} style={underlineText}>
          コードが届いていない場合
        </a>
      </div>
    </CustomerContainer>
  );
};

const underlineText = { color: 'black', fontSize: TextSize.small, textDecoration: 'underline' }
export default PINcode;