import axios from 'axios';
import { FETCH_URL, mileages, statuses } from './config';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import crypto from 'crypto';
import moment from 'moment';
import koyomi from 'koyomi';

export function getAgeOptions() {
    const startDate = new Date();
    const endDate = new Date(1960, 1, 1);

    const ageOptions = [];
    for (let i = startDate.getFullYear(); i >= endDate.getFullYear(); i--) {
        var age = {
            value: i,
            label: i
        }
        ageOptions.push(age);
    }

    return ageOptions;
};

export function getWarekiOptions() {
    const startDate = new Date();
    const endDate = new Date(1960, 1, 1);

    const ageOptions = [];
    for (let i = startDate; i >= endDate; i.setFullYear(i.getFullYear() - 1)) {

        var age = {
            value: koyomi.format(i, 'GGN年'),
            label: koyomi.format(i, 'GGN年')
        }
        ageOptions.push(age);
    }

    return ageOptions;
};

export function getMileageOptions() {
    const mileageOptions = [];
    mileages.forEach(item => {
        var mileage = {
            value: item,
            label: item
        }
        mileageOptions.push(mileage);
    });

    return mileageOptions;
};

export async function getMakerOptions() {
    return await axios.get(FETCH_URL + '/maker')
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .then((result) => {
            const makerOptions = [];
            result.forEach(item => {
                var maker = {
                    value: item.maker,
                    label: item.maker
                }
                makerOptions.push(maker);
            });

            return makerOptions;
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export async function getCarTypeOptions(maker) {
    return await axios.get(FETCH_URL + `/car_type?maker=${maker}`)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .then((result) => {
            const carTypeOptions = [];
            result.forEach(item => {
                var carType = {
                    value: item.car_type,
                    label: item.car_type
                }
                carTypeOptions.push(carType);
            });

            return carTypeOptions;
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export function getStatusOptions() {
    const statusOptions = [];
    statuses.forEach(item => {
        var status = {
            value: item[0],
            label: item[1]
        }
        statusOptions.push(status);
    });

    return statusOptions;
};

// 電話番号の有効性チェック関数
export function phoneNumberNoneProblem(val) {
    if (val) {
        const parsedPhoneNumber = parsePhoneNumber('+81' + val, 'JA');
        return parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
    } else {
        return false;
    }
}

// 電話番号を整形する関数
export function formatTel(val) {
    return new AsYouType('JP').input(val);
}

export function cryptHash(str) {
    return crypto.createHash('sha256').update(str, 'utf8').digest('hex');
}

export function Now() {
    return moment().format('YYYY-MM-DD HH:mm:ss');
}