import React from 'react';
import Modal from 'react-modal';
import Button from '../../../Components/Button';

Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1000,
  },
  content: {
    maxWidth: '500px',
    height: '150px',
    top: '50%',
    marginTop: '-100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
};

export const DeleteModal = (props) => {
  const { deleteModalState, deleteCarResistration, setDeleteModalState } = props;

  return (
    <Modal isOpen={deleteModalState} style={modalStyle}>
      <div>本当に削除してもよろしいですか？</div>
      <div className="field is-grouped is-grouped-right">
        <div className="control" style={{ paddingTop: 30, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button onClick={() => setDeleteModalState(false)} buttonStyle={'cancel'}>
            キャンセル
          </Button>
          <Button buttonStyle={'delete'} onClick={() => deleteCarResistration()}>
            削除
          </Button>
        </div>
      </div>
    </Modal>
  )
}