import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
//components
import SubmitButton from '../../Components/SubmitButton';
import { CustomerContainer } from '../../Components/StyledComponents';
//config
import { TextSize, Colors } from '../../config';

const Top = () => {
  const history = useHistory();
  const { account_name } = useParams();

  const onClick = () => {
    history.push({
      pathname: `/user/${account_name}/phone`,
    });
  };

  return (
    <CustomerContainer>
      <div style={{ textAlign: 'center', margin: '20% 0 15% 0' }}>
        <div style={{ fontSize: TextSize.small }}>
          スマホで査定
        </div>
        <div style={{ fontSize: TextSize.large, fontWeight: '600', marginTop: -4 }}>
          サテコン
        </div>
      </div>
      <div style={imageContainer}>
        <img src={require('../../assets/topImage.jpg')} style={{ width: '100%', height: '100' }} />
      </div>
      <div style={{ textAlign: 'center', padding: '8% 0 15% 0' }}>
        <div style={{ fontSize: TextSize.small, fontWeight: '600' }}>
          お手持ちのスマートフォンから
        </div>
        <div style={{ fontSize: TextSize.small, fontWeight: '600' }}>
          あなたの愛車を簡単査定！
        </div>
      </div>
      <SubmitButton buttonText='さぁ、始めましょう' onClick={() => onClick()} isEnabled={true} />
    </CustomerContainer >
  );
};

const imageContainer = {
  width: '75%',
  maxWidth: 350,
  marginLeft: 'auto',
  marginRight: 'auto'
}

const container = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 0,
  height: '100vh',
  overflow: 'scroll',
};

export default Top;