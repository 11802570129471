import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Header, Footer } from '../../Components/AdminComponents';
import { Body, TitleContainer, BodyElement, BodyItem, Container } from '../../Components/StyledComponents';
import Button from '../../Components/Button';
import axios from 'axios';
import Ajv from 'ajv';
//api
import { STORE_INFO_UPDATE_URL, UPLOAD_URL, Colors, TextSize } from '../../config';
//service
import ImageService from "../../Services/ImageService";
import AuthService from '../../Services/AuthService';
//styles
import { cntentStyle, labelStyle } from './StoreInfo';

const EditStoreInfo = () => {
  const [storeData, setStoreData] = useState({});
  const [logoURL, setLogoURL] = useState('');
  const [uploadingLogoFile, setUploadingLogoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const history = useHistory();

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    const storeLogo = user['logo_img'];
    if (storeLogo !== null) {
      const img = [{ img_name: storeLogo }];
      ImageService.getImageURL(img)
        .then((url) => {
          setLogoURL(url[0].img_name);
        })
        .catch((err) => {
          console.log(err);
        })
    }
    setStoreData({ ...user }, setIsLoading(false));
  }, []);

  const setName = useCallback((input) => {
    if (input !== null) {
      let data = { ...storeData };
      data['name'] = input.value;
      setStoreData({ ...data });
    }
  }, [storeData]);

  const setAddress = useCallback((input) => {
    if (input !== null) {
      let data = { ...storeData };
      data['address'] = input.value;
      setStoreData({ ...data });
    }
  }, [storeData]);

  const setPhoneNumber = useCallback((input) => {
    if (input !== null) {
      let data = { ...storeData };
      data['phone'] = input.value;
      setStoreData({ ...data });
    }
  }, [storeData]);

  const setEmail = useCallback((input) => {
    if (input !== null) {
      let data = { ...storeData };
      data['mail'] = input.value;
      setStoreData({ ...data });
    }
  }, [storeData]);

  const cancelEditing = useCallback(() => {
    history.goBack();
  }, []);

  const saveButtonPressed = useCallback(() => {
    const updateStoreInfo = async () => {
      let data = { ...storeData }
      if (uploadingLogoFile !== null) {
        const result = await uploadImage(uploadingLogoFile);
        const logo_img = result[0].key;
        data = {
          ...data,
          'id': storeData.id,
          'name': storeData.name,
          'address': storeData.address,
          'logo_img': logo_img,
          'phone': storeData.phone,
          'mail': storeData.mail
        };
      } else {
        data = {
          ...data,
          'id': storeData.id,
          'name': storeData.name,
          'address': storeData.address,
          'logo_img': storeData.logo_img,
          'phone': storeData.phone,
          'mail': storeData.mail
        };
      }
      try {
        const response = await axios.post(STORE_INFO_UPDATE_URL, data);
        if (response.status === 200) {
          AuthService.set('user', JSON.stringify(data));
          history.goBack();
        }
      } catch (err) {
        console.log(err)
        history.goBack();
      }
    }

    const err = checkForm(storeData);
    if (err !== '') {
      setError(err);
    } else {
      updateStoreInfo();
    }
  }, [storeData, uploadingLogoFile]);

  const checkForm = (storeData) => {
    const ajv = Ajv({ allErrors: true });
    let errorMsg = '';
    const data = {
      店舗名: storeData.name,
      住所: storeData.address,
      電話番号: storeData.phone,
      メールアドレス: storeData.mail,
    }
    const schema = {
      required: ['店舗名', '住所', '電話番号', 'メールアドレス'],
      type: 'object',
      properties: {
        メールアドレス: {
          type: 'string',
          format: 'email',
        },
      },
    };

    const validate = ajv.compile(schema);
    const valid = validate(data);

    if (!valid) {
      console.log(validate.errors);
      validate.errors.forEach(error => {
        if (error.keyword === 'required') {
          errorMsg = `必須項目を入力してください '${error.params.missingProperty}'\n`
        } else if (error.keyword === 'format') {
          errorMsg = `フォーマットが正しくありません ${error.dataPath.slice(1, -1)}\n`
        }
      })
      return errorMsg;
    }
    return errorMsg;
  }

  const uploadImage = async (file) => {
    const params = new FormData();
    params.append('file', file);
    return (await axios.post(UPLOAD_URL, params,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })).data;
  }

  const swapImage = useCallback((img) => {
    if (img.target.files.length !== 0) {
      const imageFile = img.target.files[0]; // for sending to w3-wasabi
      const imageURL = URL.createObjectURL(img.target.files[0]); // for preview
      setLogoURL(imageURL);
      setUploadingLogoFile(imageFile);
    }
  }, []);

  if (isLoading) {
    return (
      <Container>
        <Header />
        <Body>
          <TitleContainer style={{ justifyContent: 'flex-start', marginLeft: 10 }}>
            店舗情報編集
          </TitleContainer>
          <BodyElement style={{ backgroundColor: Colors.bodyBackgroundColor }}>

          </BodyElement>
        </Body>
        <Footer />
      </Container>
    )
  } else {
    return (
      <Container>
        <Header />
        <Body>
          <TitleContainer style={{ justifyContent: 'flex-start', marginLeft: 10 }}>
            店舗情報編集
          </TitleContainer>
          <BodyElement style={{ backgroundColor: Colors.bodyBackgroundColor }}>
            <div style={{ widht: '100%', padding: '1em' }}>
              <div style={cntentStyle}>
                <label style={labelStyle}>店舗名</label>
                <input onChange={(e) => setName(e.target)} style={inputContainer} value={storeData.name} />
              </div>
              <div style={cntentStyle}>
                <label style={labelStyle}>住所</label>
                <input onChange={(e) => setAddress(e.target)} style={inputContainer} value={storeData.address} />
              </div>
              <div style={cntentStyle}>
                <label style={labelStyle}>電話番号</label>
                <input onChange={(e) => setPhoneNumber(e.target)} style={inputContainer} value={storeData.phone} type='tel' />
              </div>
              <div style={cntentStyle}>
                <label style={labelStyle}>メールアドレス</label>
                <input onChange={(e) => setEmail(e.target)} style={inputContainer} value={storeData.mail} />
              </div>

              <div style={{ ...cntentStyle, marginTop: 10 }}>
                <div style={labelStyle}>
                  店舗ロゴ
                </div>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden={true}
                  onChange={(e) => swapImage(e)}
                />
                <label htmlFor="contained-button-file" style={{ width: 150, height: 150, alignSelf: 'center', marginLeft: -10, backgroundColor: 'gray' }} >
                  <img src={logoURL} alt={'Logo'} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                </label>
              </div>
              <div style={{ width: '100%', height: '8%', textAlign: 'center', color: Colors.error, fontSize: 12, alignItems: 'center' }}>
                {error && error}
              </div>
              <div style={{ width: '100%', height: '10%', display: 'flex', flexDirection: 'row', }}>
                <Button onClick={() => cancelEditing()} buttonStyle={'cancel'}>キャンセル</Button>
                <Button onClick={() => saveButtonPressed()}>保存</Button>
              </div>
            </div>
          </BodyElement>
        </Body>
        <Footer />
      </Container>
    )
  }
};

const inputContainer = { fontSize: 12, width: 300, height: 25 };

export default EditStoreInfo;