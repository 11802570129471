import axios from 'axios';
import { SIGNED_URL } from "../config.js";

class ImageService {

  getImageURL = async (snapShot) => {
    const requests = snapShot.map((item) => {
      if (item.img_name) {
        return this.fetch(item.img_name)
          .then((img_url) => {
            let row = item;
            row['img_name'] = img_url;
            return row;
          })
      } else {
        let row = item;
        row['img_name'] = null;
        return row;
      }
    })
    return Promise.all(requests);
  }

  fetch = async (imageFile) => {
    const URL = SIGNED_URL + `${imageFile}`;
    const response = await axios.get(URL);
    return response.data;
  }
}

export default new ImageService();
